import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import {
	TextField,
	InputAdornment,
	Button
	//Modal
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { THEME_MAP } from "../theme";
//import { useParams } from "react-router-dom";
import { useFetchRegions, useRegions } from "../actions/regions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export function Regions() {
	const history = useHistory();
	const { search } = useLocation();
	const regions = useRegions();
	const { fetchRegions, saveRegion, deleteRegion } =
		useFetchRegions();

	const [openModal, setOpenModal] = useState(false);
	const [selectedRegion, setSelectedRegion] = useState({});

	// The 'handleSearch' function makes the dependencies of useEffect Hook (at line 41) change on every render. To fix this, wrap the 'handleSearch' definition into its own useCallback() Hook
	const handleSearch = useCallback((e) => {
		history.push({ search: `?q=${e.target.value}` });
	}, [history]);

	useEffect(() => {
		let hasQuery = search.match(/q=([\w\W]+?)(&|$)/);
		if (hasQuery) {
			handleSearch({ target: { value: hasQuery[1] } });
		}
		fetchRegions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [handleSearch, search]);

	return (
		<>
			<Container>
				<div className="filters">
					<TextField
						className="input"
						placeholder="Filter"
						variant="outlined"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<Search>Filled</Search>
								</InputAdornment>
							)
						}}
						onChange={handleSearch}
					/>

					<Button
						color="primary"
						variant="contained"
						onClick={() => {
							setSelectedRegion({});
							setOpenModal(true);
						}}
					>
						Create Region
					</Button>
				</div>
				<div className="bars-container">
					{regions &&
						regions?.map((snapshot) => (
							<RegionListItem
								key={snapshot.id}
								regionSnapshot={snapshot}
								setSelectedRegion={setSelectedRegion}
								setOpenModal={setOpenModal}
							/>
						))}
				</div>
			</Container>
			<Dialog
				open={openModal}
				onClose={() => setOpenModal(false)}
				aria-labelledby="form-dialog-title"
				maxWidth="md"
				fullWidth
			>
				<DialogTitle id="form-dialog-title">
					{selectedRegion.id
						? "Edit region"
						: "Create new region"}
				</DialogTitle>
				<DialogContent>
					<TextField
						autoFocus
						margin="dense"
						id="name"
						label="Region Name"
						type="text"
						value={selectedRegion.Name}
						onChange={(event) =>
							setSelectedRegion({
								...selectedRegion,
								Name: event.currentTarget.value
							})
						}
						fullWidth
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpenModal(false)}>
						Cancel
					</Button>
					{selectedRegion.id && (
						<Button
							onClick={() => {
								// eslint-disable-next-line no-restricted-globals
								if (confirm("Are you sure?")) {
									deleteRegion(selectedRegion.id).then(
										() => {
											setOpenModal(false);
											fetchRegions();
										}
									);
								}
							}}
						>
							Delete
						</Button>
					)}
					<Button
						onClick={() =>
							saveRegion(
								selectedRegion.id || "new",
								selectedRegion
							).then(() => {
								setOpenModal(false);
								fetchRegions();
							})
						}
						color="primary"
					>
						Save
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.bars-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-100"]};

		padding: 8px 0;

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}
`;

function RegionListItem({
	regionSnapshot,
	setSelectedRegion,
	setOpenModal
}) {
	const [currentRef] = useState(regionSnapshot.ref);
	const [region, setRegion] = useState(regionSnapshot);
	//const history = useHistory();

	useEffect(() => {
		if (currentRef) {
			return currentRef.onSnapshot((snap) => {
				setRegion(snap);
			});
		}
	}, [currentRef]);

	const handleClick = () => {
		setSelectedRegion(region);
		setOpenModal(true);
	};

	return (
		<BarItem onClick={handleClick}>
			<span className="barname">
				{region?.Name || "Region Name"}
			</span>
		</BarItem>
	);
}

const BarItem = styled.div`
	padding: 8px 24px;
	width: 100%;
	justify-content: space-between;
	margin: 4px 0;

	&:hover {
		background: ${THEME_MAP["color-primary-transparent-100"]};
		cursor: pointer;
	}

	span {
		display: block;

		&.barname {
			color: ${THEME_MAP["color-primary-700"]};
			font-size: 1.2rem;
			/* font-weight: bold; */
		}

		&.barAddress {
			color: ${THEME_MAP["color-basic-600"]};
		}
	}
`;
