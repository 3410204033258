import React from "react";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import WarningIcon from "@mui/icons-material/Warning";
import PaymentsIcon from "@mui/icons-material/Payments";

import styled from "styled-components";

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
	width: 2em !important;
	height: 2em !important;
`;

const StyledCancelIcon = styled(CancelIcon)`
	width: 2em !important;
	height: 2em !important;
`;

const StyledWarningIcon = styled(WarningIcon)`
	width: 2em !important;
	height: 2em !important;
`;

const StyledPaymentIcon = styled(PaymentsIcon)`
	width: 2em !important;
	height: 2em !important;
`;

export const ResultIcon = ({ resultType }) => {
	switch (resultType) {
		case "success":
			return <StyledCheckCircleIcon color="success" />;

		case "already-exists":
			return <StyledWarningIcon color="warning" />;

		case "unknown":
			return <StyledCancelIcon color="error" />;

		default:
			return <StyledPaymentIcon color="primary" />;
	}
};
