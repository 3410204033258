export const AUTH_TOKEN = "AUTH_TOKEN";

export const SELECT_ADDRESS_TEXT =
	"Please select a valid address in order to get the location.";

export const PHONE_NUMBER_VALID_TEXT = "Phone number must be valid";
export const NAME_REQUIRED_TEXT = "Bar name is required";
export const EMAIL_REQUIRED_TEXT = "Bar email is required";
export const OWNER_EMAIL_REQUIRED_TEXT = "Owner email is required";
export const WEBSITE_VALID_TEXT = "Website must be valid";

export const PHONE_REGEX = /([0-9]{3}-[0-9]{3}-[0-9]{4})/;
export const WEBSITE_URL_REGEX =
	/[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;

export const MAX_HEIGHT_AD = 50;
export const MAX_WIDTH_AD = 320;
