import {
	default as React,
	useCallback,
	useState,
	useRef,
	useContext,
	useEffect
} from "react";
//import { useHistory } from "react-router-dom";
import {
	firestore,
	getDeleteSentinel,
	functions
} from "../services/firebase";

import { debounce } from "../lib";

const getUsersByBarRef = (barId) =>
	firestore
		.collection("Users")
		.where(`roles.${barId}.role`, ">", "");
// const getUsersByPromotionRef = (promoId) =>
// 	firestore
// 		.collection("Users")
// 		.where(`roles.${promoId}.role`, ">", "");
const usersByNameRef = firestore.collection("Users").orderBy("email");

const UsersContext = React.createContext();
const UserUtilsContext = React.createContext();
const BarManagersContext = React.createContext();

export function UsersProvider({ numResults = 10, children }) {
	// TODO: this could be refactored into a reducer
	const [usersQuery, setUserQuery] = useState(null);
	const [users, setUsers] = useState([]);
	const [endOfResults, setEndOfResults] = useState(false);
	const [isNewQuery, setIsNewQuery] = useState(false);

	const first = useRef();
	const last = useRef();

	const [managers, setManagers] = useState([]);

	// this seems weird but we had some cool observer stuff going on, unfortunately it prevented search
	// The 'getResults' function makes the dependencies of useEffect Hook (at line 69) change on every render. Move it inside the useEffect callback. Alternatively, wrap the 'getResults' definition into its own useCallback() Hook  react-hooks/exhaustive-deps
	const getResults = useCallback(
		(ref) => {
			return ref.get().then((usersSnapshot) => {
				if (usersSnapshot.size < numResults) {
					setEndOfResults(true);
				}

				first.current =
					usersSnapshot.size > 0 ? usersSnapshot.docs[0] : null;
				last.current =
					usersSnapshot.size === numResults
						? usersSnapshot.docs[usersSnapshot.size - 1]
						: null;

				setUsers([
					...(isNewQuery ? [] : users),
					...usersSnapshot.docs
				]);
				if (isNewQuery) setIsNewQuery(false);
			});
		},
		[isNewQuery, numResults, users]
	);

	useEffect(() => {
		if (usersQuery) {
			getResults(usersQuery);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [usersQuery]);

	const initialFetch = (search) => {
		setEndOfResults(false);
		setIsNewQuery(true);
		setUserQuery(
			usersByNameRef.limit(numResults).startAt(search || "")
		);
	};

	const getNext = (search) => {
		if (endOfResults) return;

		if (search && !usersQuery) {
			initialFetch(search);
		} else if (!usersQuery) {
			initialFetch();
		} else if (last.current) {
			setUserQuery(
				usersByNameRef.limit(numResults).startAfter(last.current)
			);
		}
	};

	const refreshSearch = () => {
		setUsers([]);
		initialFetch();
	};

	const setSearch = (text) => {
		setEndOfResults(false);
		setIsNewQuery(true);
		setUserQuery(usersByNameRef.limit(numResults).startAfter(text));
	};

	const setUsersQuery = debounce(setSearch, 250);

	const fetchManagers = (bar) => {
		let managersRef = getUsersByBarRef(bar.id);
		managersRef.onSnapshot((managersSnapshot) => {
			setManagers([
				...managersSnapshot.docs.sort((a, b) => {
					return a.get("email") > b.get("email") ? 1 : -1;
				})
			]);
		});
	};

	const setAdmin = (user, value) => {
		if (!user?.id) return Promise.reject();

		let ref = firestore.collection("Users").doc(user.id);

		return ref.update({ isAdmin: value });
	};

	const removeBarFromUser = (user, barId) => {
		if (!user?.id) return Promise.reject();

		let ref = firestore.collection("Users").doc(user.id);
		return ref.update({
			[`roles.${barId}`]: getDeleteSentinel()
		});
	};

	const removeBarFromSite = (barId) => {
		if (!barId) return Promise.reject();

		const deleteBarFromUsers = firestore
			.collection("Users")
			.where(`roles.${barId}.role`, ">", "")
			.get()
			.then((querySnapshot) => {
				querySnapshot.forEach((doc) => {
					doc.ref.update({
						[`roles.${barId}`]: getDeleteSentinel()
					});
				});
			});

		const deleteBar = firestore
			.collection("Bars")
			.doc(barId)
			.delete();

		const updateAdsStatus = firestore
			.collection("Ads")
			.where("barId", "==", barId)
			.get()
			.then((querySnapshot) => {
				const updatePromises = [];
				querySnapshot.forEach((doc) => {
					updatePromises.push(doc.ref.update({
						status: 2
					}));
				});
				return Promise.all(updatePromises);
			});
	
		return Promise.all([deleteBar, deleteBarFromUsers, updateAdsStatus]);
	};

	const removePromotionFromUser = (user, promoId) => {
		if (!user?.id) return Promise.reject();

		let ref = firestore.collection("Users").doc(user.id);
		return ref.update({
			[`roles.${promoId}`]: getDeleteSentinel()
		});
	};

	const setUserRole = (user, barSnapshot, role) => {
		if (!user?.id) return Promise.reject("No User Id");

		let ref = firestore.collection("Users").doc(user.id);
		return ref.update({
			[`roles.${barSnapshot.id}`]: {
				barName: barSnapshot.get("barName"),
				role
			}
		});
	};

	const addManager = (opts) => {
		let addMan = functions.httpsCallable("addManager");
		return addMan(opts);
	};

	const utils = {
		initialFetch,
		getNext,
		setUsersQuery,
		refreshSearch,
		setAdmin,
		removeBarFromUser,
		removeBarFromSite,
		removePromotionFromUser,
		setUserRole,
		fetchManagers,
		addManager
	};

	return (
		<UserUtilsContext.Provider value={utils}>
			<UsersContext.Provider value={{ users, endOfResults }}>
				<BarManagersContext.Provider value={managers}>
					{children}
				</BarManagersContext.Provider>
			</UsersContext.Provider>
		</UserUtilsContext.Provider>
	);
}

export const useManagers = () => useContext(BarManagersContext);
export const useUsers = () => useContext(UsersContext);
export const useUserUtils = () => useContext(UserUtilsContext);
