import { default as React, useState, useContext } from "react";

import { functions } from "../services/firebase";

const FeaturePlacementsContext = React.createContext();

export function FeaturePlacementsProvider({ children }) {
	const [featurePlacements, setFeaturePlacements] = useState([]);

	const compare = (a, b) => {
		if (
			a.metadata.duration_in_months &&
			b.metadata.duration_in_months
		) {
			if (
				parseInt(a.metadata.duration_in_months) <
				parseInt(b.metadata.duration_in_months)
			) {
				return -1;
			}
			if (
				parseInt(a.metadata.duration_in_months) >
				parseInt(b.metadata.duration_in_months)
			) {
				return 1;
			}
		}
		return 0;
	};

	const fetchFeaturePlacements = async () => {
		let sortedData = [];

		const { data: response } = await functions.httpsCallable(
			"listOfFeaturePlacements"
		)();
		
		sortedData =
			response.data.length > 0 ? response.data.sort(compare) : [];

		setFeaturePlacements(sortedData);
	};

	const verifyFeaturePlacementPayment = async (sessionId, docId) => {
		try {
			const response = await functions.httpsCallable(
				"verifyFeaturePlacement"
			)({
				sessionId,
				docId
			});

			return response.data;
		} catch (error) {
			return { result: error.code, message: error.message };
		}
	};

	return (
		<FeaturePlacementsContext.Provider
			value={{
				fetchFeaturePlacements,
				featurePlacements,
				verifyFeaturePlacementPayment
			}}
		>
			{children}
		</FeaturePlacementsContext.Provider>
	);
}

export const useFeaturePlacements = () =>
	useContext(FeaturePlacementsContext);
