import { default as React, useContext, useState } from "react";

const TitleContext = React.createContext();
const SetTitleContext = React.createContext();

export function HeaderProvider(props) {
	const [title, setTitle] = useState("");

	return (
		<SetTitleContext.Provider value={setTitle}>
			<TitleContext.Provider value={title}>
				{props.children}
			</TitleContext.Provider>
		</SetTitleContext.Provider>
	);
}

export const useSetHeaderTitle = () => useContext(SetTitleContext);
export const useHeaderTitle = () => useContext(TitleContext);
