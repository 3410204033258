/* eslint-disable no-console */
import React, { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import {
	TextField,
	InputAdornment,
	Button,
	Select,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { THEME_MAP } from "../theme";
//import { useParams } from "react-router-dom";
import { useBars, useBarUtils } from "../actions/bars";
import { useFetchRegions, useRegions } from "../actions/regions";
import { useUserUtils } from "../actions/users";

export function Bars() {
	const history = useHistory();
	const { search } = useLocation();
	const { bars, endOfResults } = useBars();
	const { getNext, clearSelectedBar /*, setBarsQuery*/ } =
		useBarUtils();
	const { fetchRegions } = useFetchRegions();
	const [pageBars, setPageBars] = useState([]);
	const regions = useRegions();
	const [selectedRegion, setSelectedRegion] = useState();
	const getRegions = useCallback(() => {
		fetchRegions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// The 'handleSearch' function makes the dependencies of useEffect Hook (at line 60) change on every render. To fix this, wrap the 'handleSearch' definition into its own useCallback() Hook  react-hooks/exhaustive-deps
	const handleSearch = useCallback(
		(e) => {
			// setBarsQuery(e.target.value);
			setPageBars(
				bars.filter(
					(b) =>
						b
							.data()
							.barName.toLowerCase()
							.indexOf(e.target.value.toLowerCase()) >= 0
				)
			);
			history.push({ search: `?q=${e.target.value}` });
		},
		[bars, history]
	);

	useEffect(() => {
		setPageBars(bars);
	}, [bars]);

	useEffect(() => {
		let hasQuery = search.match(/q=([\w\W]+?)(&|$)/);
		if (hasQuery) {
			handleSearch({ target: { value: hasQuery[1] } });
		} else {
			if (!pageBars?.length) getNext();
		}
		getRegions();
	}, [getNext, getRegions, handleSearch, pageBars.length, search]);

	const navigateToAddBar = () => {
		clearSelectedBar();
		history.push("/bar/new");
	};

	return (
		<Container>
			<div className="filters">
				<TextField
					className="input"
					placeholder="Filter"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search>Filled</Search>
							</InputAdornment>
						)
					}}
					onChange={handleSearch}
				/>

				<span>
					Filter with region
					<Select
						value={selectedRegion || ""}
						onChange={(event) => {
							setSelectedRegion(event.target.value);
						}}
						style={{ marginLeft: "10px" }}
						displayEmpty
						placeholder="Select Region"
						inputProps={{ "aria-label": "Current Role" }}
						color="primary"
					>
						<MenuItem value="">-- Select Region --</MenuItem>
						{regions.map((region) => (
							<MenuItem key={region.id} value={region.id}>
								{region.Name}
							</MenuItem>
						))}
					</Select>
				</span>

				<Button
					color="primary"
					variant="contained"
					onClick={navigateToAddBar}
				>
					Create Bar
				</Button>
			</div>
			<div className="bars-container">
				{pageBars?.map((snapshot) => (
					<BarListItem
						key={snapshot.id}
						barSnapshot={snapshot}
						selectedRegion={selectedRegion}
					/>
				))}
				{pageBars.length === 0 && (
					<NoResultsContainer>
						<span className="promotionName">No bars found</span>
					</NoResultsContainer>
				)}
			</div>
			{!endOfResults ? (
				<Button
					variant="outlined"
					color="primary"
					onClick={getNext}
				>
					Next Page
				</Button>
			) : (
				<Button
					variant="outlined"
					color="primary"
					disabled
					onClick={() => {}}
				>
					No More Results
				</Button>
			)}
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.bars-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-100"]};

		padding: 8px 0;

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}
`;

function BarListItem({ barSnapshot, selectedRegion }) {
	const [showDeleteAlert, setShowDeleteAlert] = useState(false);
	const [currentRef] = useState(barSnapshot.ref);
	const [bar, setBar] = useState({
		...barSnapshot?.data(),
		barId: barSnapshot?.id
	});
	const history = useHistory();
	//const { id: barId } = useParams();

	const { setSelectedBar } = useBarUtils();
	const { removeBarFromSite } = useUserUtils();

	useEffect(() => {
		if (currentRef) {
			return currentRef.onSnapshot((snap) => {
				setBar(snap.data());
			});
		}
	}, [currentRef]);

	const handleClick = () => {
		setSelectedBar(barSnapshot);
		history.push(`bar/${barSnapshot.id}`);
	};

	const removeBarPermissions = async () => {
		try {
			await removeBarFromSite(barSnapshot.id);
			window.location.href = "";
		} catch (err) {
			console.log(err);
		}
	};

	const dialogView = (
		<Dialog
			open={showDeleteAlert}
			onClose={() => setShowDeleteAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Are you sure?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Would you like to proceed with deleting this bar?
					<br />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => setShowDeleteAlert(false)}
					color="primary"
				>
					Cancel
				</Button>

				<Button
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						removeBarPermissions();
					}}
					color="primary"
					autoFocus
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			{(!selectedRegion ||
				selectedRegion.length < 1 ||
				selectedRegion === bar?.regionId) &&
				bar !== undefined && (
					<BarItem>
						<div onClick={handleClick}>
							<span className="barname">
								{bar?.barName || "Bar Name"}
							</span>
							<span className="barAddress">
								{bar?.barAddress || "Bar Address"}
							</span>
						</div>
						<Button
							className="delete-bar-button"
							onClick={(e) => {
								e.stopPropagation();
								e.preventDefault();
								setShowDeleteAlert(true);
							}}
							aria-label="delete bar"
							variant="outlined"
						>
							DELETE
						</Button>
						{dialogView}
					</BarItem>
				)}
		</>
	);
}

const BarItem = styled.div`
	padding: 8px 24px;
	width: 100%;
	justify-content: space-between;
	margin: 4px 0;

	&:hover {
		background: ${THEME_MAP["color-primary-transparent-100"]};
		cursor: pointer;
	}

	span {
		display: block;

		&.barname {
			color: ${THEME_MAP["color-primary-700"]};
			font-size: 1.2rem;
			/* font-weight: bold; */
		}

		&.barAddress {
			color: ${THEME_MAP["color-basic-600"]};
		}
	}

	.delete-bar-button {
		z-index: 20;
	}
`;

const NoResultsContainer = styled.div`
	padding: 8px 24px;
`;
