/* eslint-disable no-console */
import {
	default as React,
	useCallback,
	useState,
	useRef,
	useContext,
	useEffect
} from "react";
import {
	firestore,
	imageRef,
	storage,
	getDeleteSentinel,
	functions
} from "../services/firebase";

import { debounce } from "../lib";

const promotionsRef = firestore.collection("Promotions");
const promotionsByNameRef = firestore
	.collection("Promotions")
	.orderBy("promotionName");
const barRef = firestore.collection("Bars");

const PromotionsContext = React.createContext();
const SelectedPromotionContext = React.createContext();
const PromotionsUtilsContext = React.createContext();

const FetchPromotionsContext = React.createContext();
const ResetPromotionsContext = React.createContext();

export const usePromotions = () => useContext(PromotionsContext);
export const useFetchPromotions = () =>
	useContext(FetchPromotionsContext);
export const useResetSearch = () =>
	useContext(ResetPromotionsContext);

export function PromotionsProvider({ numResults = 10, children }) {

	// TODO: this could be refactored into a reducer
	const [barId, setBarId] = useState(null);
	const [promotionsQuery, setPromotionQuery] = useState(null);
	const [promotions, setPromotions] = useState([]);
	const [selectedRef, setSelectedRef] = useState(null);
	const [selectedPromotion, setSelectedPromotion] = useState(null);
	const [endOfResults, setEndOfResults] = useState(false);
	const [isNewQuery, setIsNewQuery] = useState(false);

	// const fetchPromotions = () => {
	// 	promotionsRef.get().then((promotionsSnapshot) => {
	// 		setPromotions([...promotionsSnapshot.docs]);
	// 	});
	// };

	const refreshSearch = () => {
		setIsNewQuery(true);
		setPromotions([]);
		getResults(promotionsRef);
		// initialFetch();
	};

	const first = useRef();
	const last = useRef();

	useEffect(() => {
		if (selectedRef) {
			return selectedRef.onSnapshot(setSelectedPromotion);
		}
	}, [selectedRef]);

	// this seems weird but we had some cool observer stuff going on, unfortunately it prevented search
	const getResults = useCallback(
		async (ref, bar) => {
			if (barId || bar) {
				ref = ref.where("barId", "==", barId || bar);
			}
			ref = ref.orderBy("promotionEndingHours","desc");
			return ref.get().then(async (promotionsSnapshot) => {
				if (promotionsSnapshot.size < numResults) {
					setEndOfResults(true);
				}

				first.current =
					promotionsSnapshot.size > 0
						? promotionsSnapshot.docs[0]
						: null;
				last.current =
					promotionsSnapshot.size === numResults
						? promotionsSnapshot.docs[
								promotionsSnapshot.size - 1
							]
						: null;

				const result = await Promise.all(
					promotionsSnapshot.docs.map(async (p) => {
						const bar = await firestore
							.collection("Bars")
							.doc(p.data().barId)
							.get();
						return { ...p.data(), id: p.id, bar: bar.data() };
					})
				);

				return [...(isNewQuery ? [] : promotions), ...result];
				// if (isNewQuery) setIsNewQuery(false);
			});
		},
		[barId, isNewQuery, numResults, promotions]
	);

	useEffect(() => {
		if (promotionsQuery) {
			getResults(promotionsQuery);
		}
	}, [promotionsQuery, getResults]);

	//const initialFetch = (search) => {
	// setEndOfResults(false);
	// setIsNewQuery(true);
	// setPromotionQuery(promotionsByNameRef.limit(numResults).startAt(search || ""));
	//};

	const getNext = () => {
		if (endOfResults) return;

		if (last.current) {
			setPromotionQuery(
				promotionsByNameRef
					.limit(numResults)
					.startAfter(last.current)
			);
		}
		// if (search && !promotionsQuery) {
		// 	initialFetch(search);
		// } else if (!promotionsQuery) {
		// 	initialFetch();
		// } else if (last.current) {
		// 	setPromotionQuery(
		// 		promotionsByNameRef
		// 			.limit(numResults)
		// 			.startAfter(last.current)
		// 	);
		// }
	};

	const selectPromotion = (promotionSnapshot) => {
		setSelectedPromotion(promotionSnapshot);
		setSelectedRef(promotionSnapshot.ref);
	};

	const fetchPromotionById = (promoId) => {
		setSelectedRef(promotionsRef.doc(promoId));
		return promotionsRef
			.doc(promoId)
			.get()
			.then((snapshot) => {
				barRef
					.doc(snapshot.data().barId)
					.get()
					.then((bar) => {
						const data = snapshot.data();
						data.bar = bar.data();
						data.id = snapshot.id;
						setSelectedPromotion(data);
					});
			});
	};

	const setSearch = (text) => {
		setEndOfResults(false);
		setIsNewQuery(true);
		setPromotionQuery(
			promotionsByNameRef.limit(numResults).startAfter(text)
		);
	};

	const setPromotionsQuery = debounce(setSearch, 250);

	const clearSelectedPromotion = () => {
		setSelectedRef(null);
		setSelectedPromotion(null);
	};

	const savePromotion = async (promoId, promotion) => {
		const { ...restPromotion } = promotion;
		// if (!latitude || !longitude) return;

		// let newPosition = geo.point(parseFloat(latitude), parseFloat(longitude));
		// restPromotion.position = newPosition;

		const isNew = promoId === "new";
		let promotionRef = promotionsRef.doc(
			isNew ? generateId() : promoId
		);

		await promotionRef.set(restPromotion, { merge: true });

		return promotionRef.id;
	};

	const savePromotionCategories = (categories) => {
		return selectedRef.set(
			{
				categories
			},
			{ merge: true }
		);
	};

	const deletePromotion = (promoId) => {
		return promotionsRef.doc(promoId).delete();
	};

	const uploadImage = (promoId, promotion, file) => {
		const name = generateId();
		const ext = "jpeg";
		const fileName = `${name}.${ext}`;
		return new Promise((resolve, reject) => {
			let newImageRef = imageRef.child(`${promoId}/${fileName}`);

			newImageRef
				.put(file)
				.then((imageUploadTask) => {
					// if upload is successful get image url and add image to Promotion
					let newImageUrlPromise =
						imageUploadTask.ref.getDownloadURL();

					let promotionData = {};

					if (promotion.promotionImages) {
						promotionData.promotionImages = [
							...promotion.promotionImages,
							fileName
						];
					} else {
						// if its the first image we want to use it as the cover image
						promotionData.promotionImages = [fileName];
						promotionData.promotionCoverImage = fileName;
					}

					let promotionPromise = promotionsRef
						.doc(promoId)
						.set(promotionData, { merge: true });

					Promise.all([
						newImageUrlPromise,
						promotionPromise
					]).then(() => {
						// at this point this should be a url
						resolve({
							fileName,
							downloadUrl: newImageUrlPromise
						});
					});
				})
				.catch(reject);
		});
	};

	const deleteImage = (imageUrl) => {
		return new Promise((resolve) => {
			const imgRef = storage.refFromURL(imageUrl);

			imgRef
				.delete()
				.then(() => {
					let promotionImages =
						selectedPromotion.get("promotionImages") || [];
					let fileName = imgRef.fullPath.split("/").pop();
					promotionImages = promotionImages.filter(
						(url) => url !== fileName
					);

					let promotionCoverImage = selectedPromotion.get(
						"promotionCoverImage"
					);
					if (promotionCoverImage === fileName) {
						promotionCoverImage =
							promotionImages.length > 0
								? promotionImages[0]
								: getDeleteSentinel();
					}

					selectedRef
						.set(
							{
								promotionImages,
								promotionCoverImage
							},
							{ merge: true }
						)
						.then(resolve);
				})
				.catch((err) => {
					console.log(err);
					resolve(err);
				});
		});
	};

	const markAsBannerImage = (imageUrl) => {
		const imgRef = storage.refFromURL(imageUrl);
		let fileName = imgRef.fullPath.split("/").pop();
		return selectedRef.set(
			{
				promotionCoverImage: fileName
			},
			{ merge: true }
		);
	};

	const verifyPromotionPayment = async (
		sessionId,
		promotionProductId,
		promotionId
	) => {
		try {
			const response = await functions.httpsCallable(
				"verifyPromotionPayment"
			)({
				sessionId,
				promotionProductId,
				promotionId
			});

			return response.data;
		} catch (error) {
			return { result: error.code, message: error.message };
		}
	};

	const utils = {
		//initialFetch,
		getNext,
		setPromotionsQuery,
		refreshSearch,
		fetchPromotionById,
		savePromotion,
		setSelectedPromotion: selectPromotion,
		deletePromotion,
		savePromotionCategories,
		uploadImage,
		deleteImage,
		markAsBannerImage,
		clearSelectedPromotion,
		verifyPromotionPayment
	};

	return (
		<PromotionsUtilsContext.Provider value={utils}>
			<PromotionsContext.Provider
				value={{ promotions, endOfResults, getResults, setBarId }}
			>
				<SelectedPromotionContext.Provider
					value={selectedPromotion}
				>
					{children}
				</SelectedPromotionContext.Provider>
			</PromotionsContext.Provider>
		</PromotionsUtilsContext.Provider>
	);
}

export const usePromotionUtils = () =>
	useContext(PromotionsUtilsContext);
export const useSelectedPromotion = () =>
	useContext(SelectedPromotionContext);

export function asyncGetPromotionImages(id, promotion) {
	if (!promotion?.promotionImages) return null;

	const promotionImageNames = promotion.promotionImages;
	const urls = promotionImageNames.map((name) => {
		// going to need the ids later
		return imageRef.child(`${id}/${name}`).getDownloadURL();
	});

	return new Promise((resolve) => {
		Promise.all(urls).then((res) => {
			// this seems hack but it should let us keep images in a consistent order;
			resolve(res);
		});
	});
}

export const generateId = () => {
	// Alphanumeric characters
	const chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let autoId = "";
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(
			Math.floor(Math.random() * chars.length)
		);
	}
	return autoId;
};
