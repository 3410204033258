/* eslint-disable no-unused-vars */
/**
 * Everything im here should return a promise to be used by react-query
 */
import {
	auth,
	EmailAuthProvider,
	LOCAL_PERSIST
} from "../services/firebase";

export const USER_STORAGE_KEY = "user";

export function signIn(_key, email, password) {
	return auth
		.setPersistence(LOCAL_PERSIST)
		.then(() => auth.signInWithEmailAndPassword(email, password));
}

export function signOut() {
	return auth.signOut();
}

export async function changePassword(password, newPassword) {
	const { currentUser } = auth;

	try {
		await auth.signInWithEmailAndPassword(
			currentUser.email,
			password
		);
		await currentUser.updatePassword(newPassword);
		return Promise.resolve();
	} catch (e) {
		return Promise.reject(e);
	}
}
