import React from "react";

import {
	Backdrop,
	CircularProgress,
	Typography
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
const useStyles = makeStyles(() => ({
	backdrop: {
		zIndex: 100000,
		color: "#fff"
	}
}));

export const BackDrop = ({ showBackDrop }) => {
	const classes = useStyles();

	return (
		<Backdrop className={classes.backdrop} open={showBackDrop}>
			<CircularProgress color="inherit" />
			<Typography style={{ marginLeft: 20 }}>
				Please wait. We are redirecting you to the payment page.
			</Typography>
		</Backdrop>
	);
};
