import React from "react";
import styled from "styled-components";

export function Questions() {
	return <Container>Questions Screen</Container>;
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
`;
