import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { PrivateRoute } from "./components/PrivateRoute";

import {
	SignInScreen,
	ForgotPassword,
	Bars,
	BarDetail,
	Users,
	BarManagers,
	Promotions,
	PromotionDetail,
	Regions,
	RegionDetail,
	Placements,
	Analytics,
	LocalAds
} from "./views";

import { withLayout } from "./components/Layout";
import { Header } from "./header/Header";
import { Navigation } from "./navigation/Navigation";
import { useUserRoles } from "./globalstore";
import { LoadingBlanket } from "./components/LoadingBlanket";
import { VerifyPromotionPayment, VerifyFeaturePlacementPayment } from "./views";

const withBasicLayout = withLayout(Header)(Navigation);

const BarListScreen = withBasicLayout(Bars, { title: "Manage Bars" });
const ManageBarScreen = withBasicLayout(BarDetail, {
	title: "Manage bar details"
});
const ManageUsersScreen = withBasicLayout(Users, {
	title: "Manage users"
});
const ManageBarRolesScreen = withBasicLayout(BarManagers, {
	title: "Bar managers"
});
const PromotionListScreen = withBasicLayout(Promotions, {
	title: "Manage promotions"
});
const PromotionListScreenByBar = withBasicLayout(Promotions, {
	title: "Manage promotions by bar"
});
const ManagePromotionScreen = withBasicLayout(PromotionDetail, {
	title: "Manage promotion details"
});

const RegionListScreen = withBasicLayout(Regions, {
	title: "Regions"
});
const RegionDetailScreen = withBasicLayout(RegionDetail, {
	title: "Manage regions"
});

const PlacementsScreen = withBasicLayout(Placements, {
	title: "Manage featured placements"
});

const AnalyticsScreen = withBasicLayout(Analytics, {
	title: "Analytics"
});

const VerifyPromotionPaymentScreen = withBasicLayout(VerifyPromotionPayment, {
	title: "Verify Promotion Payment"
});

const VerifyFeaturePlacementPaymentScreen = withBasicLayout(VerifyFeaturePlacementPayment, {
	title: "Verify Feature Placement Payment"
});

const LocalAdsScreen = withBasicLayout(LocalAds, {
	title: "Local advertisement"
});

function App() {
	const { userRoles: { isAdmin, roles, status }} = useUserRoles();
	if (status === "fetching") {
		return <LoadingBlanket />;
	}
	return (
		<div className="App">
			<Switch>
				<Route path="/login" component={SignInScreen} />
				<Route path="/forgotpassword" component={ForgotPassword} />
				<PrivateRoute
					adminOnly
					exact
					path="/users"
					component={ManageUsersScreen}
				/>
				<PrivateRoute
					adminOnly
					exact
					path="/bars"
					component={BarListScreen}
				/>
				<PrivateRoute
					adminOnly
					exact
					path="/promotions"
					component={PromotionListScreen}
				/>
				<PrivateRoute
					adminOnly
					exact
					path="/regions"
					component={RegionListScreen}
				/>
				<PrivateRoute
					adminOnly
					exact
					path="/region/:id"
					component={RegionDetailScreen}
				/>

				<PrivateRoute
					path="/bar/:id"
					component={ManageBarScreen}
				/>
				<PrivateRoute
					path="/managers/:id"
					component={ManageBarRolesScreen}
				/>
				<PrivateRoute
					path="/promotions/:id"
					component={PromotionListScreenByBar}
				/>
				<PrivateRoute
					path="/promotion/:id"
					component={ManagePromotionScreen}
				/>
				<PrivateRoute
					path="/premium/placements/:id"
					component={PlacementsScreen}
				/>
				<PrivateRoute
					path="/premium/analytics"
					component={AnalyticsScreen}
				/>
				<PrivateRoute
					path="/verifyPromotionPayment"
					component={VerifyPromotionPaymentScreen}
				/>
				<PrivateRoute
					path="/verifyFeaturePlacementPayment"
					component={VerifyFeaturePlacementPaymentScreen}
				/>
				<PrivateRoute
					path="/local-ads"
					component={LocalAdsScreen}
				/>
				<Route
					path="/"
					render={(props) => {
						return status === "fetching" ? (
							<LoadingBlanket />
						) : isAdmin ? (
							<Redirect to="/bars" {...props} />
						) : roles && Object.keys(roles).length > 0 ? (
							<Redirect
								to={`/bar/${Object.keys(roles)[0]}`}
								{...props}
							/>
						) : (
							<Redirect to={"/bar/new"} {...props} />
						);
					}}
				/>
				<Route
					path="/"
					render={(props) => {
						return status === "fetching" ? (
							<LoadingBlanket />
						) : isAdmin ? (
							<Redirect to="/promotions" {...props} />
						) : Object.keys(roles).length ? (
							<Redirect
								to={`/promotion/${Object.keys(roles)[0]}`}
								{...props}
							/>
						) : (
							<Redirect to={"/promotion/new"} {...props} />
						);
					}}
				/>
			</Switch>
		</div>
	);
}

export default App;
