import React from 'react';
import { FormControlLabel, FormGroup, Checkbox, Grid } from '@mui/material';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";


const OpeningHoursForm = ({ openingHours, setOpeningHours }) => {

  const handleCheckboxChange = (day) => (event) => {
    const { checked } = event.target;
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: { ...prevOpeningHours[day], isOpen: checked },
    }));
  };

  const handleTimeChange = (day, timeType) => (time) => {
    setOpeningHours((prevOpeningHours) => ({
      ...prevOpeningHours,
      [day]: { ...prevOpeningHours[day], [timeType]: time },
    }));
  };
  return (
    <FormGroup>
      {Object.keys(openingHours).map((day) => (
        <Grid container spacing={2} key={day} style={{ marginBottom: '16px' }}>
          <Grid item xs={12} sm={2}>
            <FormControlLabel
              control={<Checkbox checked={openingHours[day].isOpen} onChange={handleCheckboxChange(day)} />}
              label={day.charAt(0).toUpperCase() + day.slice(1)}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Open Time *"
                value={openingHours[day].openTime}
                onChange={handleTimeChange(day, 'openTime')}
                disabled={!openingHours[day].isOpen}
                required={openingHours[day].isOpen}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Close Time *"
                value={openingHours[day].closeTime}
                onChange={handleTimeChange(day, 'closeTime')}
                disabled={!openingHours[day].isOpen}
                required={openingHours[day].isOpen}
                minTime={openingHours[day].openTime} 
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={2}>
            <></>
          </Grid>
          <Grid item xs={12} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Open Time 2"
                value={openingHours[day].openTime2}
                onChange={handleTimeChange(day, 'openTime2')}
                disabled={!openingHours[day].isOpen}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} sm={5}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                label="Close Time 2"
                value={openingHours[day].closeTime2}
                onChange={handleTimeChange(day, 'closeTime2')}
                disabled={!openingHours[day].isOpen}
                minTime={openingHours[day].openTime2} 
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      ))}
    </FormGroup>
  );
};

export default OpeningHoursForm;
