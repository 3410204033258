import React/*, { useState }*/from "react";
import { useHistory } from "react-router-dom";
import { Button, Menu, MenuItem } from "@mui/material";

import styled from "styled-components";
import { THEME_MAP } from "../theme";

import { useHeaderTitle } from "../actions/headerContext";
import { signOut } from "../actions/auth";
import { useUserRoles } from "../globalstore";

export function Header(props) {
	const history = useHistory();
	const title = useHeaderTitle();
	const { userRoles: { email }} = useUserRoles();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleLogout = () => {
		signOut();
		history.push("/login");
	};

	return (
		<Head>
			<h2>{title || props.title || ""}</h2>
			<div className="user-menu">
				<Button onClick={handleClick}>
					{email || "Username"}
				</Button>
				<Menu
					id="simple-menu"
					anchorEl={anchorEl}
					anchorOrigin={{
						horizontal: "left",
						vertical: "bottom"
					}}
					getContentAnchorEl={null}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					<MenuItem onClick={handleLogout}>Logout</MenuItem>
				</Menu>
			</div>
		</Head>
	);
}

const Head = styled.div`
	width: 100%;
	height: 80px;

	background: ${THEME_MAP["color-basic-100"]};

	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 8px 24px;

	h2 {
		font-weight: normal;
	}

	.user-menu {
		display: inline-block;
	}
`;
