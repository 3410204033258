import { default as React, useState, useContext } from "react";
import { firestore } from "../services/firebase";

const categoriesRef = firestore
	.collection("Categories")
	.orderBy("categoryName");

const CategoriesContext = React.createContext();
const FetchCategoriesContext = React.createContext();
const ResetCategoriesContext = React.createContext();

export function CategoriesProvider({ children }) {
	const [categories, setCategories] = useState([]);

	const fetchCategories = () => {
		categoriesRef.get().then((categoriesSnapshot) => {
			setCategories([...categoriesSnapshot.docs]);
		});
	};

	const refreshSearch = () => {
		setCategories([]);
		fetchCategories();
	};

	return (
		<FetchCategoriesContext.Provider value={fetchCategories}>
			<ResetCategoriesContext.Provider value={refreshSearch}>
				<CategoriesContext.Provider value={categories}>
					{children}
				</CategoriesContext.Provider>
			</ResetCategoriesContext.Provider>
		</FetchCategoriesContext.Provider>
	);
}

export const useCategories = () => useContext(CategoriesContext);
export const useFetchCategories = () =>
	useContext(FetchCategoriesContext);
export const useResetSearch = () =>
	useContext(ResetCategoriesContext);
