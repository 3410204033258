import { default as React, useState, useContext } from "react";
import { firestore } from "../services/firebase";

const regionsRef = firestore.collection("Regions");

const RegionContext = React.createContext();
const FetchRegionContext = React.createContext();
const ResetRegionContext = React.createContext();

export function RegionsProvider({ children }) {
	const [regions, setRegions] = useState([]);

	const fetchRegions = () => {
		const data = [];
		regionsRef
			.orderBy("Name")
			.get()
			.then((regionsSnapshot) => {
				regionsSnapshot.forEach((doc) => {
					data.push({ ...doc.data(), id: doc.id });
				});
				setRegions(data);
			});
	};

	const saveRegion = (regionId, region) => {
		const isNew = regionId === "new";
		let regionRef = regionsRef.doc(isNew ? generateId() : regionId);
		return regionRef.set(region, { merge: true }).then(() => {
			return regionId;
		});
	};

	const deleteRegion = (regionId) => {
		const isNew = regionId === "new";
		let regionRef = regionsRef.doc(isNew ? generateId() : regionId);
		return regionRef.delete();
	};

	const generateId = () => {
		// Alphanumeric characters
		const chars =
			"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		let autoId = "";
		for (let i = 0; i < 20; i++) {
			autoId += chars.charAt(
				Math.floor(Math.random() * chars.length)
			);
		}
		return autoId;
	};

	const refreshSearch = () => {
		setRegions([]);
		fetchRegions();
	};

	return (
		<FetchRegionContext.Provider
			value={{ fetchRegions, saveRegion, deleteRegion }}
		>
			<ResetRegionContext.Provider value={refreshSearch}>
				<RegionContext.Provider value={regions}>
					{children}
				</RegionContext.Provider>
			</ResetRegionContext.Provider>
		</FetchRegionContext.Provider>
	);
}

export const useRegions = () => useContext(RegionContext);
export const useFetchRegions = () => useContext(FetchRegionContext);
export const useResetSearch = () => useContext(ResetRegionContext);
