import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";
import { THEME_MAP } from "../theme";

import {
	Button,
	LinearProgress,
	Tooltip,
	IconButton,
	Snackbar
} from "@mui/material";
import { Close, Delete } from "@mui/icons-material";

import { useLocalAds } from "../actions/localAds";

import { MAX_WIDTH_AD, MAX_HEIGHT_AD } from "../constants";

const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
	overflow: auto;
	position: relative;

	&.is-loading {
		overflow: hidden;
	}
`;

const BannerContainer = styled.form`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	padding: 16px 32px;
	margin-bottom: 16px;

	figure {
		position: relative;
		display: inline-flex;
	}

	figcaption {
		opacity: 0;
		transition: opacity 0.35s ease-in-out;

		width: 100%;
		bottom: 0px;
		left: 0px;

		padding: 4px 8px;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.MuiIconButton-root {
			&:hover {
				opacity: 0.6;
			}
		}
	}

	.title-ad {
		margin-bottom: 20px;
	}

	.local-ad {
		width: 320px;
		height: 50px;
	}

	.bar-img-slider {
		min-height: 272px;

		.slick-next:before,
		.slick-prev:before {
			color: ${THEME_MAP["color-primary-500"]};
		}
	}

	.no-image-holder {
		min-height: 272px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		h2,
		p {
			color: ${THEME_MAP["color-primary-500"]};
		}
	}

	.ad-example {
		margin-top: 20px;
		color: ${THEME_MAP["color-basic-100"]};
		background-color: ${THEME_MAP["color-primary-500"]};
		width: 320px;
		height: 50px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	figcaption {
		opacity: 1;
	}

	.section-footer {
		padding-top: 16px;
		display: flex;
		justify-content: flex-end;
	}

	input[type="file"] {
		visibility: hidden;
		pointer-events: none;
		width: 1px;
		height: 1px;
		position: fixed;
		top: -10;
		left: -10;
	}
`;

export const LocalAds = () => {
	const {
		localAds,
		isLoading,
		getLocalsAds,
		uploadAdImage,
		deleteAd
	} = useLocalAds();
	const fileSelectRef = useRef(null);

	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	useEffect(() => {
		getLocalsAds();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const clickFileUpload = (e) => {
		e.preventDefault();
		// eslint-disable-next-line no-unused-expressions
		fileSelectRef?.current.click();
	};

	const handleFileSelect = async () => {
		try {
			let file = fileSelectRef.current.files[0];
			await validateImage(file);
			uploadAdImage(file).then(({ fileName, downloadUrl }) => {
				console.log({ fileName, downloadUrl });
				openNote("Image Uploaded successfully.");
			});
		} catch (error) {
			openNote(error.message);
		}
	};

	const handleDeleteAd = (id) => {
		deleteAd(id);
	};

	const openNote = (note) => {
		setNote(note);
		setIsOpen(true);
	};

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	return (
		<div>
			{isLoading ? <LinearProgress /> : null}
			<Container>
				<BannerContainer>
					{localAds.length > 0 ? (
						<div>
							<h2 className="title-ad">Active ad</h2>
							<div>
								{localAds.map((ad) => {
									return (
										<figure key={ad.id}>
											<img
												className="local-ad"
												alt=""
												key={ad.url}
												src={ad.url}
											/>
											<figcaption>
												<Tooltip title="Delete Image">
													<IconButton
														onClick={() =>
															handleDeleteAd(ad.id)
														}
														size="large"
													>
														<Delete />
													</IconButton>
												</Tooltip>
											</figcaption>
										</figure>
									);
								})}
							</div>
						</div>
					) : (
						<div>
							<div className="no-image-holder">
								<h2>Upload your advertisement image here!</h2>
								<p>
									Make sure the banner has the correct
									dimension (320*50px)
								</p>

								<div className="ad-example">320x50</div>
							</div>
							<div className="section-footer">
								<Button
									color="primary"
									variant="outlined"
									onClick={clickFileUpload}
								>
									Upload Image
								</Button>
								<input
									type="file"
									accept="image/*"
									ref={fileSelectRef}
									onChange={handleFileSelect}
								/>
							</div>
						</div>
					)}
				</BannerContainer>
			</Container>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={7000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</div>
	);
};

const validateImage = function (file) {
	const reader = new FileReader();
	const image = new Image();

	return new Promise(function (resolve, reject) {
		// If the file type doesn't match chuck it
		if (!file.type.match(/image.*/)) {
			return reject(
				new Error(
					"This file is not a valid image. Make sure the logo is in JPG, PNG or GIF format."
				)
			);
		}

		reader.onload = function (readerEvent) {
			image.onload = function () {
				if (
					image.width !== MAX_WIDTH_AD ||
					image.height !== MAX_HEIGHT_AD
				) {
					return reject(
						new Error(
							"Make sure the banner has the correct dimension (320*50px)."
						)
					);
				}
				return resolve(image);
			};

			image.src = readerEvent.target.result;
		};

		reader.readAsDataURL(file);
	});
};