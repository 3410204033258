/* eslint-disable react/display-name */
import React from "react";
import styled from "styled-components";
import { HeaderProvider } from "../actions/headerContext";
import { THEME_MAP } from "../theme";

const defaultOptions = {};

export function Layout({
	header: Header,
	nav: Nav,
	content: Content,
	options: _options,
	...props
}) {
	const options = { ...defaultOptions, ..._options };

	return (
		<HeaderProvider>
			<Container>
				{Nav && <Nav {...props} />}
				<div className="content-layout">
					{Header && <Header {...props} title={options.title} />}
					{Content && <Content {...props} />}
				</div>
			</Container>
		</HeaderProvider>
	);
}

const Container = styled.div`
	min-height: 100vh;
	width: 100%;

	display: flex;
	/* we want the nav on the left */
	flex-direction: row;

	.content-layout {
		min-height: 100vh;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		background: ${THEME_MAP["color-basic-200"]};
	}
`;

export function withLayout(Header) {
	return function (Nav) {
		return function (Content, options) {
			return (props) => (
				<Layout
					header={Header}
					nav={Nav}
					content={Content}
					{...props}
					options={options}
				/>
			);
		};
	};
}
