import React from 'react';
import styled from "styled-components";

const ComingSoon = () => {
  return (
    <ComingSoonContainer>
      <div className="content">
        <h1 className="heading">Coming Soon!</h1>
        <p className="paragraph">We're working hard to give you a great experience. Stay tuned!</p>
        <div>
          <img
            className="image"
            src="/logo2.png"
            alt="Barhopper logo"
          />
        </div>
      </div>
    </ComingSoonContainer>
  );
};

const ComingSoonContainer = styled.div`
  max-width: 800px;
  margin: auto;
  margin-top: 160px;
  display: flex;
  justify-content: center; 
  align-items: center;

  .content {
    text-align: center;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .heading {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }

  .paragraph {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }

  .image {
    max-width: 30%;
    border-radius: 8px;
   }
`;

export default ComingSoon;
