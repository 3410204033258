import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

import { Button, LinearProgress } from "@mui/material";
import { ResultIcon } from "../components/ResultIcon";

import styled from "styled-components";
import { THEME_MAP } from "../theme";

import { usePromotionUtils } from "../actions/promotions";
import { useBarUtils } from "../actions/bars";

const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
	overflow: auto;
	position: relative;

	&.is-loading {
		overflow: hidden;
	}
`;

const PaymentContainer = styled.div`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	min-height: 300px;
	padding: 16px 32px;
	margin-bottom: 16px;
`;

const MessagesContainer = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	margin-top: 20px;
`;

const IconContainer = styled.div`
	margin-top: 50px;
	display: flex;
	flex-flow: column;
	align-items: center;
`;

const ButtonContainer = styled.div`
	margin-top: 50px;
	display: flex;
	flex-flow: column;
	align-items: center;
`;


const PaymentResult = ({ barId, resultType, message }) => {
	const history = useHistory();

	const { fetchBarById } = useBarUtils();
	let title = "";

	useEffect(() => {
		if (barId) fetchBarById(barId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [barId]);

	switch (resultType) {
		case "success":
			title = "Payment successful";
			break;

		case "already-exists":
			title = "Payment already verified";
			break;

		case "unknown":
			title = "We couldn't verify your payment";
			break;

		default:
			title = "Verifying your payment...";
			break;
	}

	const goToPromotions = (e) => {
		e.stopPropagation();

		if (barId) {
			history.push(`/promotions/${barId}`);
		} else {
			history.push("/promotions");
		}
	};

	return (
		<PaymentContainer>
			<IconContainer>
				<ResultIcon resultType={resultType} />
			</IconContainer>
			<MessagesContainer>
				<div>
					<h2>{title}</h2>
				</div>
				<p>{message}</p>
			</MessagesContainer>
			<ButtonContainer>
				<Button
					onClick={(e) => goToPromotions(e)}
					variant="contained"
					color="primary"
				>
					Go to promotions
				</Button>
			</ButtonContainer>
		</PaymentContainer>
	);
};

export const VerifyPromotionPayment = () => {
	const { verifyPromotionPayment } = usePromotionUtils();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);

	// access query parameters
	const session_id = queryParams.get("session_id");
	const promotion_product_id = queryParams.get(
		"promotion_product_id"
	);
	const promotion_id = queryParams.get("promotion_id");
	const bar_id = queryParams.get("bar_id");

	const [isLoading, setIsLoading] = useState(false);
	const [response, setResponse] = useState({});

	const verifyPromotion = useCallback(
		async (session_id, promotion_product_id, promotion_id) => {
			setIsLoading(true);
			const response = await verifyPromotionPayment(
				session_id,
				promotion_product_id,
				promotion_id
			);

			setResponse(response);
			setIsLoading(false);
		},
		[verifyPromotionPayment]
	);

	useEffect(() => {
		if (session_id && promotion_product_id && promotion_id) {
			verifyPromotion(
				session_id,
				promotion_product_id,
				promotion_id
			);
		}
	}, [
		session_id,
		promotion_product_id,
		promotion_id,
		verifyPromotion
	]);

	return (
		<div>
			{isLoading ? <LinearProgress /> : null}
			<Container>
				<PaymentResult
					barId={bar_id || null}
					resultType={response?.result ?? ""}
					message={
						response?.message ??
						"Please wait, we are verifying your payment transaction."
					}
				/>
			</Container>
		</div>
	);
};
