/* eslint-disable no-console */
import {
	Backdrop,
	//Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	CardHeader,
	CircularProgress,
	Container,
	//CssBaseline,
	Dialog,
	DialogContent,
	//DialogTitle,
	Divider,
	FormControlLabel,
	Grid,
	LinearProgress,
	List,
	ListItem,
	//ListItemSecondaryAction,
	ListItemText,
	Switch,
	Typography,
	FormControl,
	InputLabel,
	Select,
	//MenuItem,
	IconButton,
	ButtonGroup
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { useState, useEffect } from "react";
import {
	/*auth,*/ firestore,
	functions
} from "../../services/firebase";
import MuiDialogTitle from "@mui/material/DialogTitle";
import CloseIcon from "@mui/icons-material/Close";
import { useUserRoles } from "../../globalstore";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";
import * as moment from "moment";
import { withStyles } from "@mui/styles";
import ComingSoon from '../../components/ComingSoon';

const useStyles = makeStyles((theme) => ({
	root: {
		// maxWidth: 275,
	},
	bullet: {
		display: "inline-block",
		margin: "0 2px",
		transform: "scale(0.8)"
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	},
	backdrop: {
		zIndex: 100000,
		color: "#fff"
	},
	heroContent: {
		padding: theme.spacing(8, 0, 6)
	},
	cardHeader: {
		backgroundColor:
			theme.palette.mode === "light"
				? theme.palette.grey[200]
				: theme.palette.grey[700]
	},
	cardPricing: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		marginBottom: theme.spacing(2)
	},
	footer: {
		borderTop: `1px solid ${theme.palette.divider}`,
		marginTop: theme.spacing(8),
		paddingTop: theme.spacing(3),
		paddingBottom: theme.spacing(3),
		[theme.breakpoints.up("sm")]: {
			paddingTop: theme.spacing(6),
			paddingBottom: theme.spacing(6)
		}
	}
}));

const Analytics = () => {
	const classes = useStyles();
	const { userRoles: {
		id,
		email,
		status,
		subscription,
		//paymentProviderId,
		roles
	}} = useUserRoles();
	const [products, setProducts] = useState([]);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const [showBackdrop, setShowBackdrop] = useState(false);
	const [showYearly, setShowYearly] = useState(false);
	const [mostSearchedCategories, setMostSearchedCategories] =
		useState([]);
	//const [mostSearchedRegions, setMostSearchedRegions] = useState([]);
	const [allSearchesWithUser, setAllSearchesWithUser] = useState([]);
	const [regions, setRegions] = useState([]);
	const [selectedRegion, setSelectedRegion] = useState("");
	const [showChart, setShowChart] = useState(false);
	const [switchDataType, setSwitchDataType] = useState("monthly");
	const [selectedCategoryForChart, setSelectedCategoryForChart] =
		useState("");
	const [showProgressBar, setShowProgressBar] = useState(true);

	const searchesRef = firestore.collection("Searches");
	const regionsRef = firestore.collection("Regions");

	useEffect(() => {
		const funct = async () => {
			setShowProgressBar(true);
			if (status !== "fetching" && !subscription) {
				setShowProgressBar(false);
				showUserPlan();
				return;
			}

			const regionSnap = await regionsRef.get();
			const regions = [];
			regionSnap.forEach((s) => {
				const d = s.data();
				regions.push({ ...d, id: s.id });
			});
			setRegions(regions);
			let searchQuery = searchesRef.where("type", "==", "Search");

			if (selectedRegion.length > 5) {
				searchQuery = searchQuery.where(
					"selectedRegion",
					"==",
					selectedRegion
				);
			}
			const searchSnap = await searchQuery
				.orderBy("searchedAt", "desc")
				.get();
			let allSearchesData = {};
			let allRegionData = {};
			let allSearchesWithUserLocal = [];
			const myBars = Object.entries(roles).map(([barId]) => barId);

			await Promise.all(
				searchSnap.docs.map(async (doc) => {
					const d = doc.data();
					const isMyBarShown = d.displayedBars.filter(
						(b) => myBars.indexOf(b.barId) >= 0
					);
					if (isMyBarShown.length > 0) {
						let data = {
							...d,
							distance: isMyBarShown[0].barDistance.toFixed(2)
						};
						try {
							const user = await firestore
								.collection("Users")
								.doc(d.user)
								.get();
							data = {
								...data,
								user: user.data()
							};
						} catch (err) {
							console.log(err);
						}
						try {
							const bar = await firestore
								.collection("Bars")
								.doc(isMyBarShown[0].barId)
								.get();
							data = {
								...data,
								bar: bar.data()
							};
						} catch (err) {
							console.log(err);
						}
						allSearchesWithUserLocal.push(data);
						d.selectedCategories.map((c) => {
							if (allSearchesData[c] === undefined) {
								allSearchesData[c] = [];
							}
							allSearchesData[c].push(d.searchedAt);

							return c;
						});
						if (allRegionData[d.selectedRegion] === undefined) {
							allRegionData[d.selectedRegion] = [];
						}
						allRegionData[d.selectedRegion].push(d.searchedAt);
					}
				})
			);
			allSearchesData = Object.entries(allSearchesData)
				.sort(function (a, b) {
					return b[1].length - a[1].length;
				})
				.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

			allRegionData = Object.entries(allRegionData)
				.sort(function (a, b) {
					return b[1].length - a[1].length;
				})
				.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
			setMostSearchedCategories(allSearchesData);
			//setMostSearchedRegions(allRegionData);
			setAllSearchesWithUser(allSearchesWithUserLocal);
			setShowProgressBar(false);
		};
		funct();
	}, [
		subscription,
		status,
		selectedRegion,
		regionsRef,
		roles,
		searchesRef
	]);

	const showUserPlan = async () => {
		const data = await functions.httpsCallable("listProducts")();
		setProducts(data.data);
		setShowPlanModal(true);
	};

	// const visitMyCustomerPortal = async () => {
	// 	const data = await functions.httpsCallable(
	// 		"visitMyCustomerPortal"
	// 	)({ paymentProviderId, currentLocation: window.location.href });
	// 	const url = data.data;
	// 	window.location.href = url;
	// };

	const selectThisPlan = (product) => {
		setShowBackdrop(true);
		var planId = product.prices.monthly.id;
		if (showYearly) {
			planId = product.prices.yearly.id;
		}
		functions
			.httpsCallable("selectSubscriptionPlan")({
				planId: planId,
				customerEmail: email,
				currentUserId: id,
				currentLocation: window.location.href
			})
			.then((data) => {
				const url = data.data;
				window.location.href = url;
			});
	};

	const showChartFor = (category) => {
		setShowChart(true);
		setSelectedCategoryForChart(category);
	};

	const showPlans = () => {
		return (
			<>
				<Container
					maxWidth="sm"
					component="main"
					className={classes.heroContent}
				>
					<Typography
						component="h1"
						variant="h2"
						align="center"
						color="textPrimary"
						gutterBottom
					>
						Pricing
					</Typography>
					<Typography
						variant="h5"
						align="center"
						color="textSecondary"
						component="p"
					>
						Select the plan that suits you to enjoy the
						analytics.
					</Typography>
					<FormControlLabel
						style={{ float: "right", marginBottom: 20 }}
						control={
							<Switch
								checked={showYearly}
								onChange={(e) =>
									setShowYearly(e.target.checked)
								}
								color="primary"
							/>
						}
						label="Billed Yearly"
					/>
				</Container>
				{/* End hero unit */}
				<Container maxWidth="md" component="main">
					<Grid container spacing={5} alignItems="flex-end">
						{products &&
							products.map((tier) => (
								<Grid
									key={tier.name}
									item
									xs={12}
									sm={tier.name === "Enterprise" ? 12 : 6}
									md={4}
								>
									<Card>
										<CardHeader
											title={tier.name}
											subheader={tier.subheader}
											titleTypographyProps={{
												align: "center"
											}}
											subheaderTypographyProps={{
												align: "center"
											}}
											action={
												tier.name === "Pro"
													? "star"
													: null
											}
											className={classes.cardHeader}
										/>
										<CardContent>
											<div className={classes.cardPricing}>
												{showYearly && (
													<>
														<Typography
															component="h2"
															variant="h3"
															color="textPrimary"
														>
															$
															{tier.prices.yearly
																.unit_amount &&
																tier.prices.yearly
																	.unit_amount / 100}
														</Typography>
														<Typography
															variant="h6"
															color="textSecondary"
														>
															/
															{
																tier.prices.yearly
																	.recurring.interval
															}
														</Typography>
													</>
												)}
												{!showYearly && (
													<>
														<Typography
															component="h2"
															variant="h3"
															color="textPrimary"
														>
															$
															{tier.prices.monthly
																.unit_amount &&
																tier.prices.monthly
																	.unit_amount / 100}
														</Typography>
														<Typography
															variant="h6"
															color="textSecondary"
														>
															/
															{
																tier.prices.monthly
																	.recurring.interval
															}
														</Typography>
													</>
												)}
											</div>
											<ul>
												{/* {tier.description.map((line) => (
                                            <Typography component="li" variant="subtitle1" align="center" key={line}>
                                                {line}
                                            </Typography>
                                        ))} */}
											</ul>
										</CardContent>
										<CardActions>
											<Button
												fullWidth={true}
												variant={"contained"}
												onClick={() =>
													selectThisPlan(tier)
												}
												color="primary"
											>
												Get Started
											</Button>
										</CardActions>
									</Card>
								</Grid>
							))}
					</Grid>
				</Container>
			</>
		);
	};
	const showBackdropForRedirect = () => (
		<Backdrop className={classes.backdrop} open={showBackdrop}>
			<CircularProgress color="inherit" />
			<Typography style={{ marginLeft: 20 }}>
				Please wait. We are redirecting you to the payment page.
			</Typography>
		</Backdrop>
	);

	const mostSearchedCategory = () => (
		<Card className={classes.root}>
			<CardContent>
				<Typography
					className={classes.title}
					color="textPrimary"
					gutterBottom
					style={{ fontSize: "18px" }}
				>
					Most Searched Category
				</Typography>
				<Typography
					variant="body2"
					component="p"
					style={{ paddingTop: "20px" }}
				>
					{Object.keys(mostSearchedCategories).map((key) => {
						return (
							<Chip
								onClick={() => showChartFor(key)}
								key={key}
								label={
									key +
									" (" +
									mostSearchedCategories[key].length +
									")"
								}
								style={{
									marginRight: "10px",
									marginBottom: "10px"
								}}
							/>
						);
					})}
				</Typography>
			</CardContent>
		</Card>
	);

	// const mostSearchedRegion = () => (
	// 	<Card className={classes.root}>
	// 		<CardContent>
	// 			<Typography
	// 				className={classes.title}
	// 				color="textPrimary"
	// 				gutterBottom
	// 				style={{ fontSize: "18px" }}
	// 			>
	// 				Most Searched Region
	// 			</Typography>
	// 			<Typography
	// 				variant="body2"
	// 				component="p"
	// 				style={{ paddingTop: "20px" }}
	// 			>
	// 				{Object.keys(mostSearchedRegions).map((key) => {
	// 					return (
	// 						<Chip
	// 							label={
	// 								key +
	// 								" (" +
	// 								mostSearchedRegions[key].length +
	// 								")"
	// 							}
	// 							style={{
	// 								marginRight: "10px",
	// 								marginBottom: "10px"
	// 							}}
	// 						/>
	// 					);
	// 				})}
	// 			</Typography>
	// 		</CardContent>
	// 	</Card>
	// );

	const recentActivity = () => (
		<Card className={classes.root} style={{ height: "70vh" }}>
			<CardContent>
				<Typography
					className={classes.title}
					color="textPrimary"
					gutterBottom
					style={{ fontSize: "18px" }}
				>
					Recent Activity
				</Typography>
				<List style={{ maxHeight: "300px", overflow: "scroll" }}>
					{allSearchesWithUser.map((user, index) => (
						<React.Fragment key={index}>
							<ListItem alignItems="flex-start">
								<ListItemText
									primary={
										<Typography>
											A
											{user.user &&
												user.user.age &&
												" " +
													user.user.age +
													" year old "}
											{user.user &&
												user.user.gender &&
												" " + user.user.gender}{" "}
											user within {user.distance}mi
											searched for{" "}
											{user.selectedCategories.length > 0
												? user.selectedCategories.join(
														", "
													)
												: "everything"}
											.
										</Typography>
									}
									secondary={
										<React.Fragment>
											<Typography
												component="span"
												variant="body2"
												className={classes.inline}
												color="textPrimary"
											>
												{moment(
													user.searchedAt.toDate()
												).fromNow()}
											</Typography>
											— your <b>{user.bar.barName}</b> was
											shown in this search
										</React.Fragment>
									}
								/>
							</ListItem>
							<Divider component="li" />
						</React.Fragment>
					))}
				</List>
			</CardContent>
		</Card>
	);

	ChartJS.register(
		CategoryScale,
		LinearScale,
		BarElement,
		Title,
		Tooltip,
		Legend
	);
	// const options = {
	// 	responsive: true,
	// 	plugins: {
	// 		legend: {
	// 			position: "top"
	// 		},
	// 		title: {
	// 			display: true,
	// 			text: "Chart.js Bar Chart"
	// 		}
	// 	}
	// };

	const styles = (theme) => ({
		root: {
			margin: 0,
			padding: theme.spacing(2)
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500]
		}
	});

	const getChartOptionsData = () => {
		const labels = [];
		let data1 = [];
		//const data2 = [];
		const type = switchDataType;
		if (type === "monthly") {
			data1 = [...Array(12).keys()].map(() => 0);
			[...Array(12).keys()].map((key) =>
				labels.push(
					moment().subtract(key, "month").format("MMM YY")
				)
			);

			allSearchesWithUser
				.filter((a) =>
					a.selectedCategories.includes(selectedCategoryForChart)
				)
				.forEach((u) => {
					let searchedAt;
					try {
						searchedAt = moment(u.searchedAt.toDate());
					} catch {
						searchedAt = moment(u.searchedAt);
					}
					[...Array(12).keys()].map((key) => {
						if (key === searchedAt.month()) {
							data1[key] = data1[key] + 1;
						}

						return key;
					});
				});
		}

		if (type === "daily") {
			data1 = [...Array(30).keys()].map(() => 0);
			[...Array(30).keys()].map((key) => {
				labels.push(
					moment().subtract(key, "day").format("DD MMM")
				);

				return key;
			});

			allSearchesWithUser
				.filter((a) =>
					a.selectedCategories.includes(selectedCategoryForChart)
				)
				.forEach((u) => {
					let searchedAt;
					try {
						searchedAt = moment(u.searchedAt.toDate());
					} catch {
						searchedAt = moment(u.searchedAt);
					}
					[...Array(30).keys()].map((key) => {
						if (
							moment()
								.subtract(key, "day")
								.format("DD-MM-YYYY") ===
							searchedAt.format("DD-MM-YYYY")
						) {
							data1[key] = data1[key] + 1;
						}
						return key;
					});
				});
		}

		// if (type === "weekly") {
		//     data1 = [...Array(52).keys()].map(k => 0);
		//     [...Array(52).keys()].map(key => labels.push(moment().subtract(key, 'week').weekYear()))

		//     allSearchesWithUser.filter(a => a.selectedCategories.includes(selectedCategoryForChart)).forEach(u => {
		//         let searchedAt;
		//         try {
		//             searchedAt = moment(u.searchedAt.toDate());
		//         } catch {
		//             searchedAt = moment(u.searchedAt);
		//         }
		//         [...Array(30).keys()].map(key => {
		//             if (moment().subtract(key, 'day').week() === searchedAt.week()) {
		//                 data1[key] = data1[key] + 1;
		//             }
		//         });
		//     })
		// }

		const data = {
			labels: labels.reverse(),
			datasets: [
				{
					label: "User Searches",
					data: data1.reverse(),
					backgroundColor: "rgba(255, 99, 132, 0.5)"
				}
				// {
				//     label: 'Dataset 2',
				//     data: [0, 10, 11, 13, 14, 15, 15],
				//     backgroundColor: 'rgba(53, 162, 235, 0.5)',
				// },
			]
		};
		return { labels, data };
	};
	const DialogTitle = withStyles(styles)((props) => {
		const { children, classes, onClose, ...other } = props;
		return (
			<MuiDialogTitle
				disableTypography
				className={classes.root}
				{...other}
			>
				<Typography variant="h6">{children}</Typography>
				{onClose ? (
					<IconButton
						aria-label="close"
						className={classes.closeButton}
						onClick={onClose}
						size="large"
					>
						<CloseIcon />
					</IconButton>
				) : null}
			</MuiDialogTitle>
		);
	});

	const chart = () => {
		let { data, options } = getChartOptionsData();
		return (
			<Dialog
				fullWidth={true}
				maxWidth={"xl"}
				onClose={() => setShowChart(false)}
				aria-labelledby="customized-dialog-title"
				open={showChart}
			>
				<DialogTitle
					id="customized-dialog-title"
					onClose={() => setShowChart(false)}
				>
					Chart for {selectedCategoryForChart}
				</DialogTitle>
				<DialogContent dividers>
					<ButtonGroup
						color="primary"
						style={{ float: "right" }}
						aria-label="outlined primary button group"
					>
						<Button
							onClick={() => setSwitchDataType("daily")}
							variant={
								switchDataType === "daily" && "contained"
							}
						>
							Daily
						</Button>
						{/* <Button onClick={() => setSwitchDataType("weekly")} variant={switchDataType === "weekly" && "contained"}>Weekly</Button> */}
						<Button
							onClick={() => setSwitchDataType("monthly")}
							variant={
								switchDataType === "monthly" && "contained"
							}
						>
							Monthly
						</Button>
					</ButtonGroup>
					<Bar options={options} data={data} />
				</DialogContent>
			</Dialog>
		);
	};

	return ( <ComingSoon/> );


	if (status !== "idle" || showProgressBar) {
		return <LinearProgress />;
	}
	
	if (showPlanModal) {
		return (
			<Container style={{ paddingTop: 20 }}>
				{showPlans()}
				{showBackdropForRedirect()}
			</Container>
		);
	}
	if (subscription) {
		return (
			<Container style={{ paddingTop: 20 }}>
				<Grid container spacing={3}>
					<Grid item xs={6}></Grid>
					<Grid item xs={6}>
						<FormControl style={{ width: "100%" }}>
							<InputLabel id="demo-simple-select-label">
								Select Region
							</InputLabel>
							<Select
								native
								value={selectedRegion}
								onChange={(e) =>
									setSelectedRegion(e.currentTarget.value)
								}
								inputProps={{
									name: "region",
									id: "age-native-simple"
								}}
							>
								<option value="all">All</option>
								{regions.map((region) => (
									<option key={region.id} value={region.id}>
										{region.Name}
									</option>
								))}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
				{/* <Button onClick={() => visitMyCustomerPortal()}>Manage My Subscription</Button> */}
				<Grid container spacing={3}>
					<Grid item xs={6}>
						{mostSearchedCategory()}
					</Grid>
					<Grid item xs={6}>
						{recentActivity()}
					</Grid>
				</Grid>
				{showBackdropForRedirect()}
				{chart()}
			</Container>
		);
	}
	return <LinearProgress />;
};

export default Analytics;
