import { default as React, useState, useContext } from "react";

import { functions } from "../services/firebase";

const PromotionPlansContext = React.createContext();

export function PromotionPlansProvider({ children }) {
	const [promotionPlans, setPromotionPlans] = useState([]);

	const compare = (a, b) => {
		if (
			a.metadata.duration_in_minutes &&
			b.metadata.duration_in_minutes
		) {
			if (
				parseInt(a.metadata.duration_in_minutes) <
				parseInt(b.metadata.duration_in_minutes)
			) {
				return -1;
			}
			if (
				parseInt(a.metadata.duration_in_minutes) >
				parseInt(b.metadata.duration_in_minutes)
			) {
				return 1;
			}
		}
		return 0;
	};

	const fetchPromotionPlans = async () => {
		let sortedData = [];

		const { data: response } = await functions.httpsCallable(
			"listOfPromotionPlans"
		)();

		sortedData =
			response.data.length > 0 ? response.data.sort(compare) : [];

		setPromotionPlans(sortedData);
	};

	return (
		<PromotionPlansContext.Provider
			value={{ fetchPromotionPlans, promotionPlans }}
		>
			{children}
		</PromotionPlansContext.Provider>
	);
}

export const usePromotionPlans = () =>
	useContext(PromotionPlansContext);
