import { ThemeProvider } from "@mui/styles";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { StateProvider } from "./globalstore";
import "./index.css";
import { theme } from "./theme";

import { createRoot } from "react-dom/client";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
		<Router>
			<StateProvider>
				<ThemeProvider theme={theme}>
					<App />
				</ThemeProvider>
			</StateProvider>
		</Router>
);
