/* eslint-disable no-console */
import {
	default as React,
	useCallback,
	useState,
	useRef,
	useContext,
	useEffect
} from "react";
import { useHistory } from "react-router-dom";
import {
	firestore,
	imageRef,
	geo,
	storage,
	getDeleteSentinel
} from "../services/firebase";

import { debounce } from "../lib";
// import { auth } from "../services/firebase";
//import { useUserUtils } from "./users";
import { useUserRoles } from "../globalstore";
import firebase from "firebase";


const barsRef = firestore.collection("Bars");
const barsByNameRef = firestore.collection("Bars").orderBy("barName");

const BarsContext = React.createContext();
const SelectedBarContext = React.createContext();
const BarUtilsContext = React.createContext();

export function BarsProvider({ numResults = 10000, children }) {
	const history = useHistory();

	// TODO: this could be refactored into a reducer
	const [barsQuery, setBarQuery] = useState(null);
	const [bars, setBars] = useState([]);
	const [selectedRef, setSelectedRef] = useState(null);
	const [selectedBar, setSelectedBar] = useState(null);
	const [endOfResults, setEndOfResults] = useState(false);
	const [isNewQuery, setIsNewQuery] = useState(false);
	//const { setUserRole } = useUserUtils();
	const {
		userRoles: { id: userid }
	} = useUserRoles();

	const first = useRef();
	const last = useRef();

	useEffect(() => {
		if (selectedRef) {
			return selectedRef.onSnapshot(setSelectedBar);
		}
	}, [selectedRef]);

	// this seems weird but we had some cool observer stuff going on, unfortunately it prevented search
	// The 'getResults' function makes the dependencies of useEffect Hook (at line 69) change on every render. Move it inside the useEffect callback. Alternatively, wrap the 'getResults' definition into its own useCallback() Hook  react-hooks/exhaustive-deps
	const getResults = useCallback(
		(ref) => {
			return ref.get().then((barsSnapshot) => {
				if (barsSnapshot.size < numResults) {
					setEndOfResults(true);
				}

				first.current =
					barsSnapshot.size > 0 ? barsSnapshot.docs[0] : null;
				last.current =
					barsSnapshot.size === numResults
						? barsSnapshot.docs[barsSnapshot.size - 1]
						: null;
				const currentBars = [
					...(isNewQuery ? [] : bars),
					...barsSnapshot.docs
				];
				setBars(currentBars);
				if (isNewQuery) setIsNewQuery(false);
			});
		},
		[bars, isNewQuery, numResults]
	);

	useEffect(() => {
		if (barsQuery) {
			getResults(barsQuery);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [barsQuery]);

	const initialFetch = (search) => {
		setEndOfResults(false);
		setIsNewQuery(true);
		setBarQuery(
			barsByNameRef.limit(numResults).startAt(search || "")
		);
	};

	const getNext = (search) => {
		if (endOfResults) return;

		if (search && !barsQuery) {
			initialFetch(search);
		} else if (!barsQuery) {
			initialFetch();
		} else if (last.current) {
			setBarQuery(
				barsByNameRef.limit(numResults).startAfter(last.current)
			);
		}
	};

	const selectBar = (barSnapshot) => {
		setSelectedBar(barSnapshot);
		setSelectedRef(barSnapshot.ref);
	};

	const fetchBarById = (barId) => {
		setSelectedRef(barsRef.doc(barId));
		return barsRef
			.doc(barId)
			.get()
			.then((snapshot) => setSelectedBar(snapshot));
	};

	const refreshSearch = () => {
		setBars([]);
		initialFetch();
	};

	const setSearch = (text) => {
		setEndOfResults(false);
		setIsNewQuery(true);
		if (text.length > 0) {
			setBarQuery(
				barsRef
					.orderBy("barNameLower")
					.startAt(text.toLowerCase())
					.endAt(text.toLowerCase() + "\uf8ff")
			);
		} else {
			refreshSearch();
		}
	};

	const setBarsQuery = debounce(setSearch, 250);

	const clearSelectedBar = () => {
		setSelectedRef(null);
		setSelectedBar(null);
	};

	const saveBar = (barId, bar, days) => {
		const _ = require('lodash');
		const { latitude, longitude, ...modifiedRestBar } = bar;
		const restBar = _.cloneDeep(modifiedRestBar);
		days.forEach(day => {
			if (!restBar.hours[day].isOpen) {
				restBar.hours[day].openTime = null;
				restBar.hours[day].closeTime = null;
				restBar.hours[day].openTime2 = null;
				restBar.hours[day].closeTime2 = null;
			} else {
				restBar.hours[day].openTime = restBar.hours[day].openTime != null ? firebase.firestore.Timestamp.fromDate(restBar.hours[day].openTime.toDate()) : null;
				restBar.hours[day].closeTime = restBar.hours[day].closeTime != null ? firebase.firestore.Timestamp.fromDate(restBar.hours[day].closeTime.toDate()) : null;
				restBar.hours[day].openTime2 = restBar.hours[day].openTime2 != null ? firebase.firestore.Timestamp.fromDate(restBar.hours[day].openTime2.toDate()) : null;
				restBar.hours[day].closeTime2 = restBar.hours[day].closeTime2 != null ? firebase.firestore.Timestamp.fromDate(restBar.hours[day].closeTime2.toDate()) : null;
			}
		});
		if (!latitude || !longitude) return;
		let newPosition = geo.point(
			parseFloat(latitude),
			parseFloat(longitude)
		);
		restBar.position = newPosition;
		restBar.barNameLower = bar.barName.toLowerCase();
		const isNew = barId === "new";
		const id = isNew ? generateId() : barId;
		let barRef = barsRef.doc(id);
		restBar.barId = id;
		return barRef.set(restBar, { merge: true }).then(() => {
			if (isNew) {
				let ref = firestore.collection("Users").doc(userid);
				return ref
					.update({
						[`roles.${id}`]: {
							barName: bar.barName,
							role: "owner"
						}
					})
					.then(() => {
						return insertNewBarIntoList(barRef).then(() => {
							history.push(`/bar/${barRef.id}`);
							return { id: barRef.id, isNew: true };
						});
					});
			}
		});
	};

	const insertNewBarIntoList = (barRef) => {
		return barRef.get().then((snapshot) => {
			let newBar = snapshot.data();

			return setBars((current) => {
				if (current.length === 0) {
					return [snapshot];
				}

				let searchIndex = 0;
				let [lowerBound, upperBound] = [0, current.length - 1];

				while (lowerBound < upperBound) {
					searchIndex = Math.round(
						(upperBound - lowerBound) / 2
					);
					let bar = current[searchIndex].data();

					if (bar.barName < newBar.barName) {
						lowerBound = searchIndex;
					}
					if (bar.barName < newBar.barName) {
						upperBound = searchIndex;
					} else {
						break;
					}
				}

				if (searchIndex === 0) {
					return current; // No need to update
				}

				// if searchIndex is inside of bounds insert into list
				if (searchIndex < current.length - 1) {
					let firstHalf = current.slice(0, searchIndex - 1);
					let lastHalf = current.slice(
						searchIndex,
						current.length
					);
					return [...firstHalf, snapshot, ...lastHalf];
				}

				// the case that we can reasonable assume its not included in list but should have been
				if (searchIndex < numResults) {
					return [...current, snapshot];
				}
			});
		});
	};

	const saveBarCategories = (categories) => {
		return selectedRef.set(
			{
				categories
			},
			{ merge: true }
		);
	};

	const uploadImage = (barId, bar, file) => {
		const name = generateId();
		const ext = "jpeg";
		const fileName = `${name}.${ext}`;
		return new Promise((resolve, reject) => {
			let newImageRef = imageRef.child(`${barId}/${fileName}`);

			newImageRef
				.put(file)
				.then((imageUploadTask) => {
					// if upload is successful get image url and add image to bar
					let newImageUrlPromise =
						imageUploadTask.ref.getDownloadURL();

					let barData = {};

					barData.barImages = [...bar.barImages, fileName];

					if (!bar.barImages.length) {
						// if its the first image we want to use it as the cover image
						barData.barCoverImage = fileName;
					}

					let barPromise = barsRef
						.doc(barId)
						.set(barData, { merge: true });

					Promise.all([newImageUrlPromise, barPromise]).then(
						() => {
							// at this point this should be a url
							resolve({
								fileName,
								downloadUrl: newImageUrlPromise
							});
						}
					);
				})
				.catch(reject);
		});
	};

	const deleteImage = (imageUrl) => {
		return new Promise((resolve) => {
			const imgRef = storage.refFromURL(imageUrl);

			imgRef
				.delete()
				.then(() => {
					let barImages = selectedBar.get("barImages") || [];
					let fileName = imgRef.fullPath.split("/").pop();
					barImages = barImages.filter(
						(url) => url !== fileName
					);

					let barCoverImage = selectedBar.get("barCoverImage");
					if (barCoverImage === fileName) {
						barCoverImage =
							barImages.length > 0
								? barImages[0]
								: getDeleteSentinel();
					}

					selectedRef
						.set(
							{
								barImages,
								barCoverImage
							},
							{ merge: true }
						)
						.then(resolve);
				})
				.catch((err) => {
					console.log(err);
					resolve(err);
				});
		});
	};

	const markAsBannerImage = (imageUrl, isBanner) => {
		const imgRef = storage.refFromURL(imageUrl);
		let fileName = isBanner ? "" : imgRef.fullPath.split("/").pop();

		return selectedRef.set(
			{
				barCoverImage: fileName
			},
			{ merge: true }
		);
	};

	const utils = {
		initialFetch,
		getNext,
		setBarsQuery,
		refreshSearch,

		fetchBarById,
		saveBar,
		setSelectedBar: selectBar,

		saveBarCategories,
		uploadImage,
		deleteImage,
		markAsBannerImage,

		clearSelectedBar
	};

	return (
		<BarUtilsContext.Provider value={utils}>
			<BarsContext.Provider value={{ bars, endOfResults }}>
				<SelectedBarContext.Provider value={selectedBar}>
					{children}
				</SelectedBarContext.Provider>
			</BarsContext.Provider>
		</BarUtilsContext.Provider>
	);
}

export const useBars = () => useContext(BarsContext);
export const useBarUtils = () => useContext(BarUtilsContext);
export const useSelectedBar = () => useContext(SelectedBarContext);

export function asyncGetBarImages(id, bar) {
	if (!bar?.barImages) return null;

	const barImageNames = bar.barImages;
	const urls = barImageNames.map((name) => {
		// going to need the ids later
		return imageRef.child(`${id}/${name}`).getDownloadURL();
	});

	return new Promise((resolve) => {
		Promise.all(urls).then((res) => {
			// this seems hack but it should let us keep images in a consistent order;
			resolve(res);
		});
	});
}

const generateId = () => {
	// Alphanumeric characters
	const chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let autoId = "";
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(
			Math.floor(Math.random() * chars.length)
		);
	}
	return autoId;
};
