import { default as React, useReducer, useState } from "react";
import styled from "styled-components";
import {
	TextField,
	Button,
	Dialog,
	DialogContent,
	DialogActions
} from "@mui/material";

import { signIn } from "../actions/auth";

//linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(196,45,62,1) 100%);
export function SignInScreen(props) {
	const [state, dispatch] = useReducer(
		loginReducer,
		initialLoginState
	);
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleChange = (e) => {
		e.preventDefault();
		let key = e.target.name;
		let value = e.target.value;

		if (state.err)
			dispatch({ type: "change", key: "err", value: "" });

		dispatch({ type: "change", key, value });
	};

	const handleLogin = () => {
		signIn(null, state.email, state.password)
			.then(() => {
				props.history.push("/bars");
			})
			.catch((err) => {
				const message =
					err.code === "auth/user-not-found" ||
					err.code === "auth/wrong-password"
						? "Invalid username or password. Please try again."
						: err.message;

				dispatch({
					type: "change",
					key: "err",
					value: message
				});
				setIsDialogOpen(true);
			});
	};

	return (
		<Container>
			<div className="login-container">
				<img alt="Bar Hopper" src="/logo2.png" />
				<form
					noValidate
					onSubmit={() => handleLogin}
					style={{ width: "300px" }}
				>
					<TextField
						className="input"
						label="Email"
						variant="outlined"
						name="email"
						value={state.email || null}
						onChange={handleChange}
					/>

					<TextField
						className="input"
						type="password"
						variant="outlined"
						label="Password"
						name="password"
						value={state.password || null}
						onChange={handleChange}
					/>
					<Button
						className="button"
						color="primary"
						variant="contained"
						onClick={handleLogin}
					>
						Sign In
					</Button>
					<Button
						className="button"
						color="primary"
						variant="outlined"
						onClick={() =>
							props.history.push("/forgotpassword")
						}
					>
						Forgot Password
					</Button>
				</form>
			</div>
			<Dialog open={isDialogOpen}>
				<DialogContent>{state.err}</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						variant="contained"
						onClick={() => setIsDialogOpen(false)}
					>
						OK
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

const Container = styled.div`
	height: 100vh;
	width: 100%;

	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(196, 45, 62, 1) 100%
	);

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	img {
		width: 200px;
		height: auto;
		margin-bottom: 32px;
	}

	.login-container {
		background: #f0f0f0;

		border-radius: 4px;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);

		display: flex;
		flex-direction: column;
		align-items: center;

		padding: 32px;
	}

	form {
		display: flex;
		flex-direction: column;
		align-items: center;

		width: 100%;

		& > * {
			margin-bottom: 16px;
		}

		& > input {
			width: 100%;
		}

		.button {
			max-width: 400px;
			width: 100%;
		}

		.input {
			/* min-width: 100%; */
			max-width: 300px;
			width: 200%;
		}

		.bottom-links {
			color: #1976d2;
			text-decoration: underline;
		}
	}
`;

const initialLoginState = {
	email: "",
	password: "",
	err: ""
};

function loginReducer(state, action) {
	switch (action.type) {
		case "change":
			return updateValue(state, action.key, action.value);
		case "reset":
			return initialLoginState;
		default:
			return state;
	}
}

function updateValue(state, key, value) {
	return {
		...state,
		[key]: value
	};
}
