import React, { useContext, useState } from "react";
import { firestore, localAdsImageRef } from "../services/firebase";

const localAdsRef = firestore.collection("LocalAds");

const LocalAdsContext = React.createContext();

const generateId = () => {
	// Alphanumeric characters
	const chars =
		"ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let autoId = "";
	for (let i = 0; i < 20; i++) {
		autoId += chars.charAt(
			Math.floor(Math.random() * chars.length)
		);
	}
	return autoId;
};

export function LocalAdsProvider({ children }) {
	const [isLoading, setIsLoading] = useState(false);
	const [localAds, setLocalsAds] = useState([]);

	const uploadAdImage = (file) => {
		const adId = generateId();
		const type = file.type;
		const ext = type.replace("image/", "");
		const fileName = `${adId}.${ext}`;
		return new Promise((resolve, reject) => {
			let newImageRef = localAdsImageRef.child(fileName);

			newImageRef
				.put(file)
				.then((imageUploadTask) => {
					let newImageUrlPromise =
						imageUploadTask.ref.getDownloadURL();

					const ad = {
						status: true,
						fileName
					};

					let adsPromise = localAdsRef
						.doc(adId)
						.set(ad, { merge: true });

					Promise.all([newImageUrlPromise, adsPromise]).then(
						() => {
                            getLocalsAds();
							resolve({
								fileName,
								downloadUrl: newImageUrlPromise
							});
						}
					);
				})
				.catch(reject);
		});
	};

	const deleteAd = (id) => {
        setIsLoading(true);
		localAdsRef
			.doc(id)
			.set({ status: false }, { merge: true })
			.then(() => {
				getLocalsAds();
			})
			.catch((err) => {
                setIsLoading(false);
				console.log(err);
			});
	};

	const getLocalsAds = () => {
		setIsLoading(true);
		const data = [];
		localAdsRef
			.where("status", "==", true)
			.get()
			.then(async (localsAdsSnapshot) => {
				localsAdsSnapshot.forEach((doc) => {
					data.push({ ...doc.data(), id: doc.id });
				});

				const ads = await getLocalAdsImages(data);
				setLocalsAds(ads);
				setIsLoading(false);
			})
			.catch((err) => {
				console.log(err);
				setIsLoading(false);
			});
	};

	const getLocalAdsImages = async (localAds) => {
		const ads = await Promise.all(
			localAds.map(async (localAd) => {
				const url = await localAdsImageRef
					.child(localAd.fileName)
					.getDownloadURL();

				return { ...localAd, url };
			})
		);

		return ads;
	};

	return (
		<LocalAdsContext.Provider
			value={{
				isLoading,
				localAds,
				uploadAdImage,
				getLocalsAds,
                deleteAd
			}}
		>
			{children}
		</LocalAdsContext.Provider>
	);
}

export const useLocalAds = () => useContext(LocalAdsContext);
