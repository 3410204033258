import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router-dom";

import {
	TextField,
	InputAdornment,
	Button,
	Accordion,
	AccordionDetails,
	AccordionSummary,
	FormControlLabel,
	Checkbox,
	Snackbar,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText
} from "@mui/material";
import { Search, ExpandMore, Close } from "@mui/icons-material";
import { THEME_MAP } from "../theme";

import { useUsers, useUserUtils } from "../actions/users";
import { useUserRoles } from "../globalstore";

export function Users() {
	const history = useHistory();
	const { search } = useLocation();
	const { users, endOfResults } = useUsers();
	const { getNext, setUsersQuery } = useUserUtils();

	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	const openNote = (message) => {
		setNote(message);
		setIsOpen(true);
	};

	// // The 'handleSearch' function makes the dependencies of useEffect Hook (at line 41) change on every render. To fix this, wrap the 'handleSearch' definition into its own useCallback() Hook
	const handleSearch = useCallback(
		(e) => {
			setUsersQuery(e.target.value);
			history.push({ search: `?q=${e.target.value}` });
		},
		[history, setUsersQuery]
	);

	useEffect(() => {
		let hasQuery = search.match(/q=([\w\W]+?)(&|$)/);
		if (hasQuery) {
			handleSearch({ target: { value: hasQuery[1] } });
		} else {
			if (!users?.length) getNext();
		}
	}, [getNext, handleSearch, search, users.length]);

	return (
		<Container>
			<div className="filters">
				<TextField
					className="input"
					placeholder="Filter"
					variant="outlined"
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<Search>Filled</Search>
							</InputAdornment>
						)
					}}
					onChange={handleSearch}
				/>
			</div>
			<div className="users-container">
				{users?.map((snapshot) => (
					<UserListItem
						key={snapshot.id}
						userSnapshot={snapshot}
						openNote={openNote}
					/>
				))}
			</div>
			{!endOfResults ? (
				<Button
					variant="outlined"
					color="primary"
					onClick={getNext}
				>
					Next Page
				</Button>
			) : (
				<Button
					variant="outlined"
					color="primary"
					disabled
					onClick={() => {}}
				>
					No More Results
				</Button>
			)}
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.users-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-200"]};

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}

	.MuiExpansionPanelSummary-content {
		& > span {
			width: 50%;
		}
	}

	.MuiExpansionPanel-root.Mui-expanded {
		margin: 16px 0;
	}

	.MuiButtonBase-root.MuiExpansionPanelSummary-root {
		&:hover {
			background: ${THEME_MAP["color-primary-transparent-100"]};
		}
	}
	.MuiExpansionPanelDetails-root {
		flex-direction: column;
		padding: 8px 0 16px;
		p {
			padding: 8px 16px;
		}
	}

	span {
		&.username {
			color: ${THEME_MAP["color-primary-700"]};
			font-size: 1.2rem;
			/* font-weight: bold; */
		}

		&.userAddress {
			color: ${THEME_MAP["color-basic-600"]};
		}
	}

	.bar-role-item {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 12px 0;

		&:hover {
			background: ${THEME_MAP["color-primary-transparent-100"]};
		}

		& > span {
			&:first-of-type {
				padding-left: 16px;
			}

			&::last-of-type {
				padding-right: 16px;
			}
		}

		.spacing-48 {
			width: 48px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			color: rgba(0, 0, 0, 0.5);

			&:hover {
				color: rgba(0, 0, 0, 0.8);
			}
		}

		.barName {
			width: calc(50% - 24px);

			&:hover {
				cursor: pointer;
				color: ${THEME_MAP["color-primary-700"]};
			}
		}
		.role {
			width: calc(50% - 24px);
			padding-left: 2rem;
		}

		.MuiButton-root {
			margin-right: 16px;
		}
	}
`;

function UserListItem({ userSnapshot, openNote }) {
	const { userRoles: { id: currentUserId }} = useUserRoles();
	const [currentRef] = useState(userSnapshot.ref);
	const [user, setUser] = useState({
		id: userSnapshot?.id,
		...(userSnapshot?.data() || {})
	});
	const [roles, setRoles] = useState([]);

	const { setAdmin, removeBarFromUser, removePromotionFromUser } =
		useUserUtils();

	const [showConfirmationAlert, setShowConfirmationAlert] =
		useState(false);
	const [selectedUser, setSelectedUser] = useState({});

	useEffect(() => {
		if (currentRef) {
			return currentRef.onSnapshot((snap) => {
				if (snap) {
					const data = snap.data();
					setUser({ id: snap.id, ...data });

					if (data.roles) {
						setRoles(
							Object.entries(data.roles)
								.map(([barId, roleData]) => {
									return { barId, ...roleData };
								})
								.sort((a, b) =>
									a.barName > b.barName ? 1 : -1
								)
						);
					}
				}
			});
		}
	}, [currentRef]);

	const handleAdminClick = () => {
		setAdmin(selectedUser.user, selectedUser.checked)
			.then(() => {
				openNote("Successfully updated user");
				handleCancel();
			})
			.catch(() => {
				openNote("We were unable to update the user");
				handleCancel();
			});
	};

	const handleCheck = (e) => {
		setShowConfirmationAlert(true);
		setSelectedUser({
			user,
			checked: e.target.checked
		});
	};

	const handleCancel = () => {
		setShowConfirmationAlert(false);
		setSelectedUser({});
	};

	const removeBarPermissions = (e, user, barId, promoId) => {
		e.stopPropagation();
		removeBarFromUser(user, barId);
		removePromotionFromUser(user, promoId);
	};

	const dialogView = (
		<Dialog
			open={showConfirmationAlert}
			onClose={() => setShowConfirmationAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Are you sure?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Would you like to proceed with changing the role of this user?
					<br />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleCancel}
					color="primary"
				>
					Cancel
				</Button>
				<Button
					onClick={handleAdminClick}
					color="primary"
					autoFocus
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);

	return (
		<>
			<Accordion square={false}>
				<AccordionSummary expandIcon={<ExpandMore />}>
					<span className="username">
						{user?.email || "(anonymous)"}
					</span>
					<FormControlLabel
						onClick={(event) => event.stopPropagation()}
						onFocus={(event) => event.stopPropagation()}
						control={
							<Checkbox
								checked={user?.isAdmin}
								onClick={handleCheck}
								name="isAdmin"
								color="primary"
								disabled={user.id === currentUserId}
							/>
						}
						label="Admin"
					/>
				</AccordionSummary>
				<AccordionDetails>
					{dialogView}
					{roles.length ? (
						roles.map((role) => (
							<UserPermissionItem
								key={role.barId}
								role={role}
								removePermission={(e, barId, promoId) => {
									removeBarPermissions(
										e,
										user,
										barId,
										promoId
									);
								}}
							/>
						))
					) : (
						<p>This user is not a bar owner or manager</p>
					)}
				</AccordionDetails>
			</Accordion>
		</>
	);
}

function UserPermissionItem({ role, removePermission }) {
	const history = useHistory();
	const navigateToBar = () => {
		history.push(`/managers/${role.barId}`);
	};
	return (
		<div className="bar-role-item">
			<span className="barName" onClick={navigateToBar}>
				{role.barName}
			</span>
			<span className="role">{role.role.toUpperCase()}</span>
			<Button
				onClick={(e) => removePermission(e, role.barId)}
				onFocus={(event) => event.stopPropagation()}
				aria-label="remove permissions"
				variant="outlined"
			>
				DELETE
			</Button>
		</div>
	);
}
