import React from "react";
import { Route, Redirect } from "react-router-dom";

import { auth } from "../services/firebase";
import { useUserRoles } from "../globalstore";

export function PrivateRoute(props) {
	const { component: Component, adminOnly, ...rest } = props;
	const { currentUser } = auth;
	const { userRoles: { isAdmin }} = useUserRoles();
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAdmin) return <Component {...props} />;
				if (currentUser && !adminOnly)
					return <Component {...props} />;
				if (currentUser && adminOnly) return <Redirect to="/" />;
				return <Redirect to="/login" />;
			}}
		/>
	);
}
