import {
	Dialog,
	DialogTitle,
	DialogContent,
	Grid,
	FormControl,
	Chip,
	Snackbar,
	IconButton,
	Button
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Select from "react-select";
import { BackDrop } from "../../components/BackDrop";

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
	asyncGetBarImages,
	useBarUtils,
	useSelectedBar
} from "../../actions/bars";
import { firestore, functions } from "../../services/firebase";
import { THEME_MAP } from "../../theme";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { generateId } from "../../actions/promotions";
import { useUserRoles } from "../../globalstore";
import { useFeaturePlacements } from "../../actions/featurePlacements";

import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import firebase from "firebase";

const Placements = () => {
	const {
		userRoles: { email }
	} = useUserRoles();
	const { fetchBarById } = useBarUtils();
	const barSnapshot = useSelectedBar();

	const [bar, setBar] = useState();
	const [price, setPrice] = useState();
	const [imgUrls, setImgUrls] = useState([]);
	const [showCreateModal, setShowCreateModal] = useState(false);
	const [categories, setCategories] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [ads, setAds] = useState([]);
	const [showBackDrop, setShowBackdrop] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	const [dateRanges, setDateRanges] = useState({
		startDate: dayjs(),
		endDate: dayjs()
	});

	const { fetchFeaturePlacements, featurePlacements } =
		useFeaturePlacements();
	const [selectedFeaturePlacement, setSelectedFeaturePlacement] =
		useState(null);

	const { id } = useParams();

	const adsRef = firestore.collection("Ads");

	const openNote = (note) => {
		setNote(note);
		setIsOpen(true);
	};

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	const handleDateRanges = (newValue) => {
		const startDate = dayjs(newValue);

		const endDate = selectedFeaturePlacement
			? startDate.add(
					selectedFeaturePlacement.metadata.duration_in_months,
					"month"
				)
			: dateRanges.endDate;

		setDateRanges({
			startDate,
			endDate
		});
	};

	const handleOnChangeFeaturePlacement = (newFeaturePlacement) => {
		setSelectedFeaturePlacement(newFeaturePlacement);

		const endDate = dateRanges.startDate.add(
			newFeaturePlacement.metadata.duration_in_months,
			"month"
		);

		setDateRanges({
			endDate,
			startDate: dateRanges.startDate
		});
	};

	const handleSave = (e) => {
		e.preventDefault();

		if (selectedCategories.length <= 0) {
			openNote("At least one category must be selected");
			return;
		}

		setShowBackdrop(true);

		const id = generateId();
		let ads = adsRef.doc(id);

		const stripePriceId = selectedFeaturePlacement.default_price.id;

		const adsValue = {
			categories: selectedCategories.map(
				(category) => category.value
			),
			startDate: firebase.firestore.Timestamp.fromDate(
				dateRanges.startDate.toDate()
			),
			endDate: firebase.firestore.Timestamp.fromDate(
				dateRanges.endDate.toDate()
			),
			totalPrice: price,
			stripePriceId,
			barId: bar.id,
			status: 2
		};
		ads.set(adsValue, { merge: true }).then(() => {
			functions
				.httpsCallable("selectFeaturePlacement")({
					barId: barSnapshot.id,
					id,
					price: stripePriceId,
					email,
					currentLocation: window.location.href,
					categories: adsValue.categories
				})
				.then((url) => {
					window.location.href = url.data;
				});
		});
	};

	useEffect(() => {
		// fetch the images if we have a bar, or fetch the bar if we don't have it yet
		setBar(null);
		fetchBarById(id);
		firestore
			.collection("Ads")
			.where("barId", "==", id)
			.where("status", "==", 1)
			.orderBy("endDate", "desc")
			.get()
			.then((snapshot) => {
				const ads = snapshot.docs.map((doc) => {
					return { ...doc.data(), id: doc.id };
				});
				setAds(ads);
			});
		firestore
			.collection("Categories")
			.orderBy("categoryName")
			.get()
			.then((categories) => {
				const categoriesData = [
					...categories.docs.map((d) => {
						return {
							label: d.data().categoryName,
							options: d.data().subcategories.map((s) => {
								return {
									label: s,
									value: s
								};
							})
						};
					})
				];
				setCategories(categoriesData);
			});

		fetchFeaturePlacements();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	useEffect(() => {
		if (barSnapshot && barSnapshot.data) {
			const bar = barSnapshot?.data();
			setBar({ ...bar, id: barSnapshot?.id });
			setImgUrls([]);
			if (bar?.barImages) {
				asyncGetBarImages(id, bar).then((res) => {
					if (!res || res.length < 1) {
						return;
					}

					let imgs = [];
					while (imgs.length < 4) {
						imgs.push(...res);
					}
					setImgUrls(imgs);
				});
			}
		}
	}, [barSnapshot, categories, selectedFeaturePlacement]);

	useEffect(() => {
		if (selectedFeaturePlacement) {
			setPrice(selectedFeaturePlacement.default_price.unit_amount / 100 * selectedCategories.length)
		}
	}, [dateRanges, selectedCategories, selectedFeaturePlacement]);
	const groupStyles = {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between"
	};
	// const groupBadgeStyles = {
	// 	backgroundColor: "#EBECF0",
	// 	borderRadius: "2em",
	// 	color: "#172B4D",
	// 	display: "inline-block",
	// 	fontSize: 12,
	// 	fontWeight: "normal",
	// 	lineHeight: "1",
	// 	minWidth: 1,
	// 	padding: "0.16666666666667em 0.5em",
	// 	textAlign: "center"
	// };

	const formatGroupLabel = (data) => (
		<div style={groupStyles}>
			<span>{data.label}</span>
		</div>
	);

	return (
		<Container>
			<div className="bar-actions"></div>
			<Button
				onClick={() => setShowCreateModal(true)}
				variant="contained"
				color="primary"
				style={{ float: "right", marginBottom: "20px" }}
			>
				+ New
			</Button>
			<div className="bars-container">
				<div className="bars-list">
					{ads.length < 1 ? (
						<div style={{ padding: "20px" }}>
							This is the area where you can see all the ads
							you have created.
							<br />
							Ads appear on top of the searches when users are
							searching via tags.
							<br />
							<br />
							You can create a new ad by clicking the button
							above.
						</div>
					) : (
						""
					)}
					{ads.map((ad, index) => {
						return (
							<FeatPlacementItem>
								<Grid key={index} container spacing={4}>
									<Grid item xs={12}>
										<Grid
											item
											xs={8}
											style={{
												float: "left"
											}}
										>
											{ad.categories.map((cat, index) => (
												<Chip
													key={index}
													style={{
														width: "100px",
														borderColor: "#A8203C",
														color: "#A8203C",
													}}
													label={cat}
													variant="outlined"
												/>
											))}
										</Grid>
										<Grid
											item
											xs={4}
											style={{
												float: "right",
											}}
										>
											<h3 style={{ marginRight: "20px" }}>
												Total Price
												<span
													style={{
														color: "#C42D3E",
														fontWeight: "bold",
														marginLeft: "10px"
													}}
												>
													${ad.totalPrice}
												</span>
											</h3>
										</Grid>
									</Grid>
									<Grid item xs={8}
											style={{
												float: "left"
											}}
									>
										<p
											style={{
												color: "#777777"
											}}
										>
											{dayjs(ad.startDate.toDate()).format(
												"MMM DD, YYYY"
											)}{" "}
											-{" "}
											{dayjs(ad.endDate.toDate()).format(
												"MMM DD, YYYY"
											)}{" "}
										</p>
										<Chip
											style={{
												fontSize: "12px",
												width: "auto",
												height: "auto",
												background: "#C42D3E",
												color: "white"
											}}
											label={`${
												dayjs(ad.endDate.toDate()).diff(
													ad.startDate.toDate(),
													"days"
												) + 1
											} days`}
											variant="outlined"
										/>
									</Grid>
								</Grid>
							</FeatPlacementItem>
						);
					})}
				</div>
			</div>
			<Dialog
				aria-labelledby="customized-dialog-title"
				onClose={() => setShowCreateModal(false)}
				maxWidth="lg"
				fullWidth={true}
				open={showCreateModal}
			>
				<DialogTitle id="customized-dialog-title">
					New featured placement
					<Button
						style={{ float: "right" }}
						onClick={() => setShowCreateModal(false)}
					>
						X
					</Button>
				</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={4}>
						<Grid item xs={6}>
							<img
								alt=""
								key="banner"
								src={imgUrls.length > 0 ? imgUrls[0] : null}
								height="268px"
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "10px"
								}}
							>
								<label>Categories</label>
							</FormControl>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "20px"
								}}
							>
								<Select
									isMulti
									placeholder="Select a category"
									onChange={(value) =>
										setSelectedCategories(value)
									}
									options={categories}
									formatGroupLabel={formatGroupLabel}
									menuPosition="fixed"
								/>
							</FormControl>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "10px"
								}}
							>
								<label>Start Date</label>
							</FormControl>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "20px"
								}}
							>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
								>
									<DatePicker
										disablePast={true}
										value={dateRanges.startDate}
										// onChange={(newValue) =>
										// 	setStartDate(newValue)
										// }
										onChange={handleDateRanges}
									/>
								</LocalizationProvider>
							</FormControl>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "10px"
								}}
							>
								<label>Duration *</label>
							</FormControl>
							<FormControl
								style={{
									width: "100%",
									marginBottom: "20px"
								}}
							>
								<Select
									defaultValue={selectedFeaturePlacement}
									placeholder="Select a duration"
									onChange={handleOnChangeFeaturePlacement}
									options={featurePlacements.map(
										(fp, index) => {
											return {
												key: fp.id,
												label: fp.metadata.label,
												value: { ...fp },
												...fp
											};
										}
									)}
									menuPosition="fixed"
								/>
							</FormControl>
							{selectedFeaturePlacement ? (
								<FormControl
									style={{
										width: "100%",
										marginTop: "20px"
									}}
								>
									<label>
										End Date:{" "}
										<span
											style={{
												color: "#C42D3E",
												fontWeight: "bold"
											}}
										>
											{dateRanges.endDate.format(
												"MM/DD/YYYY"
											)}
										</span>
									</label>
									<label>
										Total:{" "}
										<span
											style={{
												color: "#C42D3E",
												fontWeight: "bold"
											}}
										>
											$
											{price}
										</span>
									</label>
								</FormControl>
							) : null}
							<FormControl
								style={{
									width: "200px",
									marginTop: "30px",
									float: "right"
								}}
							>
								<Button
									variant="contained"
									onClick={handleSave}
									disabled={!selectedFeaturePlacement}
									color="primary"
								>
									Pay now{" "}
									{selectedFeaturePlacement
										? `$(${
												price
											})`
										: ""}
								</Button>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			{showBackDrop ? (
				<BackDrop showBackDrop={showBackDrop} />
			) : null}
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Container>
	);
};

const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.bars-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-100"]};

		padding: 8px 0;

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}
`;


const FeatPlacementItem = styled.div`
	padding: 8px 24px;
	width: 100%;

	&:hover {
		background: ${THEME_MAP["color-primary-transparent-100"]};
		cursor: pointer;
	}
`;

export default Placements;
