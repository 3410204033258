import React, { useState, useEffect } from "react";
import {
	NavLink,
	useHistory,
	useLocation,
	useParams
} from "react-router-dom";
import {
	Group,
	Store,
	Add,
	LanguageOutlined,
	Campaign,
	AdsClick
} from "@mui/icons-material";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

import styled from "styled-components";

import { THEME_MAP } from "../theme";

import { useUserRoles } from "../globalstore";
import { FormControl, Select, MenuItem } from "@mui/material";
import { useBarUtils, useSelectedBar } from "../actions/bars";
import { functions } from "../services/firebase";

export function Navigation() {
	const { userRoles: { isAdmin, roles }} = useUserRoles();
	return (
		<Nav>
			<img
				alt="Bar Hopper"
				src="/logo2.png"
				height="100px"
				width="100px"
			/>
			<BarOwnersNav roles={roles} />
			{isAdmin === "true" ||
				(isAdmin === true && (
					<>
						<h3>Admin Menu</h3>
						<NavLink to="/bars">
							<Store />
							Bars
						</NavLink>
						<NavLink to="/regions">
							<LanguageOutlined />
							Regions
						</NavLink>
						<NavLink to="/users">
							<Group />
							Users
						</NavLink>
						<NavLink to="/promotions" exact>
							<Campaign />
							Promotions
						</NavLink>
						<NavLink to="/local-ads" exact>
							<AdsClick />
							Local advertisement
						</NavLink>
					</>
				))}
		</Nav>
	);
}

const Nav = styled.nav`
	width: 300px;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	background: ${THEME_MAP["color-primary-500"]};

	a {
		display: flex;
		align-items: center;
		padding: 8px 24px;
		width: 100%;

		color: ${THEME_MAP["color-primary-200"]};

		&.active {
			color: ${THEME_MAP["color-basic-100"]};
			background: linear-gradient(
				to right,
				${THEME_MAP["color-primary-400"]},
				${THEME_MAP["color-primary-400"]} 75%,
				${THEME_MAP["color-primary-500"]} 100%
			);
			box-shadow: -35px 1px 6px rgba(0, 0, 0, 0.1);
		}

		&:hover {
			color: ${THEME_MAP["color-basic-100"]};
			text-decoration: none;
		}

		svg {
			margin-right: 8px;
			width: 1.6rem;
			height: 1.6rem;
		}
	}

	img {
		width: 100px;
		height: auto;

		margin: 16px auto 32px;
	}

	h3 {
		width: 100%;
		color: ${THEME_MAP["color-basic-100"]};
		margin-top: 32px;
		padding-left: 16px;
	}
`;

const barOwnerPathReg = /(bar|managers|promotions|premium\/placements)\/[\w\d]{20}$/; // this should match urls that are for barOwners

function BarOwnersNav({ roles }) {
	const history = useHistory();
	const location = useLocation();
	const { id: pathId } = useParams();
	const { subscription, paymentProviderId } = useUserRoles();
	const { fetchBarById, setSelectedBar } = useBarUtils();
	const selectedBar = useSelectedBar();

	const [bars, setBars] = useState([]);
	//const [promotions, setPromotions] = useState([]);
	const [subscriptionUrl, setSubscriptionUrl] = useState("");

	useEffect(() => {
		if (roles) {
			const tempBars = Object.entries(roles).map(
				([barId, barRole]) => {
					return { id: barId, ...barRole };
				}
			);
			setBars(tempBars);
			let bar = selectedBar || tempBars[0];
			if (bar) {
				setSelectedBar(bar);
				fetchBarById(bar.id);
			}
			if (paymentProviderId) {
				functions
					.httpsCallable("visitMyCustomerPortal")({
						paymentProviderId,
						currentLocation: window.location.href
					})
					.then((data) => {
						const url = data.data;
						setSubscriptionUrl(url);
					});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [roles, paymentProviderId]);

	const handleBarsSelect = (e) => {
		if (e.target.value === "noop") return;
		fetchBarById(e.target.value.id);
		let tempPath = location.pathname;
		if (tempPath.match(barOwnerPathReg)) {
			tempPath = tempPath.replace(pathId, e.target.value.id);
			history.push(tempPath);
		}
	};

	return (
		<BarOwnerNavContainer>
			<FormControl className={"manage-bar-select"}>
				<Select
					value={selectedBar || ""}
					renderValue={(value) => value?.get?.("barName") || ""}
					onChange={handleBarsSelect}
					displayEmpty
					inputProps={{ "aria-label": "Current Bar" }}
					color="primary"
					className="selectWidth"
				>
					{bars.map((bar) => (
						<MenuItem key={bar.id} value={bar}>
							{bar.barName}
						</MenuItem>
					))}
					{/* {promotions.map((promotion) => (
						<MenuItem key={promotion.id} value={promotion}>
							{promotion.promotionName}
						</MenuItem>
					))} */}
					<MenuItem
						onClick={(e) => {
							e.stopPropagation();
							history.push("/bar/new");
						}}
						onFocus={(e) => e.stopPropagation()}
						value={"noop"}
						className="create-bar"
						style={{
							display: "flex",
							alignItems: "center",
							color: THEME_MAP["color-primary-500"]
						}}
					>
						<Add
							style={{
								margin: "-2px 0.1rem 0px -4px"
							}}
						/>
						Create Bar
					</MenuItem>
				</Select>
			</FormControl>

			<NavLink to={`/bar/${selectedBar?.id}`}>
				<Store />
				Bar profile
			</NavLink>
			<NavLink to={`/managers/${selectedBar?.id}`}>
				<Group />
				Bar managers
			</NavLink>
			<NavLink to={`/promotions/${selectedBar?.id}`}>
				<Campaign />
				Promotions
			</NavLink>
			<h3
				style={{
					fontSize: "16px",
					marginBottom: "10px",
					textTransform: "uppercase"
				}}
			>
				Premium Services
			</h3>
			<NavLink to={`/premium/placements/${selectedBar?.id}`}>
				<img
					src="/premium-placement.png"
					style={{
						width: "23px",
						height: "auto",
						margin: "0px",
						marginRight: "10px"
					}}
					alt="Premium Placements"
				/>
				Featured placements
			</NavLink>
			<NavLink to={"/premium/analytics"}>
				<AssessmentIcon />
				Analytics
			</NavLink>
			{subscription && (
				<a href={subscriptionUrl}>
					<AccountBalanceIcon />
					Billing
				</a>
			)}
		</BarOwnerNavContainer>
	);
}

const BarOwnerNavContainer = styled.section`
	width: 100%;

	.manage-bar-select {
		width: 100%;
		padding: 0 16px;

		color: ${THEME_MAP["color-basic-100"]};

		/* These are all overrides of the Material-UI defaults */
		.MuiInputBase-input {
			color: ${THEME_MAP["color-basic-100"]};
		}
		.MuiInput-underline:before {
			border-color: transparent;
		}
		.MuiInput-underline:hover:not(.Mui-disabled):before {
			border-color: transparent;
		}
		.MuiSelect-icon {
			color: ${THEME_MAP["color-basic-100"]};
		}
		.MuiSelect-select:focus {
			background: none;
		}
	}
	.selectWidth {
  		width: 270px;
	}
`;
