import {
	default as React,
	useEffect,
	useState,
	useReducer,
	//useRef,
	useCallback
} from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from "styled-components";
import {
	TextField,
	Button,
	// FormControl,
	// InputLabel,
	// Select,
	// Input,
	// Chip,
	// MenuItem,
	//Tooltip,
	IconButton,
	Snackbar,
	CircularProgress
} from "@mui/material";
import { /*Star, Delete,*/ Close } from "@mui/icons-material";
//import { default as Slider } from "react-slick";

import {
	useSelectedBar,
	asyncGetBarImages,
	useBarUtils
} from "../actions/bars";
// import {
// 	useCategories,
// 	useFetchCategories
// } from "../actions/categories";
//import { useUserRoles } from "../globalstore";
import { useSetHeaderTitle } from "../actions/headerContext";
import { THEME_MAP } from "../theme";
import { auth } from "../services/firebase";
import { useFetchRegions/*, useRegions*/ } from "../actions/regions";

export function RegionDetail() {
	//const history = useHistory();
	const { id } = useParams();
	//const isNew = id === "new";
	//const { isAdmin } = useUserRoles();

	const barSnapshot = useSelectedBar();
	const { fetchBarById } = useBarUtils();

	const [bar, setBar] = useState();
	const [isFetching, setIsFetching] = useState();
	//const [imgUrls, setImgUrls] = useState([]);

	const [isOpen, setIsOpen] = useState(false);
	const [note, setNote] = useState("");

	const setHeaderTitle = useSetHeaderTitle();

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsOpen(false);
	};

	const openNote = (message) => {
		setNote(message);
		setIsOpen(true);
	};

	useEffect(() => {
		// fetch the images if we have a bar, or fetch the bar if we don't have it yet
		if (barSnapshot && barSnapshot.id === id) {
			let _bar = barSnapshot.barName
				? barSnapshot
				: barSnapshot.data();
			setBar(_bar);
			//setImgUrls([]);
			if (_bar?.barImages) {
				asyncGetBarImages(id, _bar).then((res) => {
					if (!res || res.length < 1) {
						return;
					}

					let imgs = [];
					while (imgs.length < 4) {
						imgs.push(...res);
					}
					//setImgUrls(imgs);
				});
			}
		} else if (id) {
			setBar(null);
			if (id !== "new") {
				setIsFetching(true);
				fetchBarById(id).then(() => setIsFetching(false));
			}
		}
	}, [barSnapshot, id, fetchBarById]);

	useEffect(() => {
		setHeaderTitle(id === "new" && "Create new region");
	}, [id, setHeaderTitle]);

	if (isFetching)
		return (
			<Container className={"is-loading"}>
				<LoadingFog className={"loading-fog is-loading"}>
					<CircularProgress color="primary" />
				</LoadingFog>
			</Container>
		);

	return (
		<Container>
			<RegionInfoSection bar={bar} openNote={openNote} />
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	padding: 24px;
	overflow: auto;
	position: relative;

	&.is-loading {
		overflow: hidden;
	}

	.bar-actions {
		max-width: 800px;
		margin: auto;
		display: flex;
		justify-content: flex-end;
	}
`;

const LoadingFog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	background: rgba(255, 235, 235, 0.6);
	&.is-loading {
		display: flex;
	}
`;

// const sliderSettings = {
// 	className: "bar-img-slider",
// 	infinite: true,
// 	slidesToShow: 2,
// 	speed: 500,
// 	variableWidth: true,
// 	centerMode: true,
// 	adaptiveHeight: true
// };

function RegionInfoSection({ bar, openNote }) {
	const history = useHistory();
	const { currentUser } = auth;

	const { id } = useParams();
	//const regions = useRegions();
	const fetchRegions = useFetchRegions();
	const isNew = id === "new";

	const { saveBar } = useBarUtils();
	const [barState, barDispatch] = useReducer(
		regionInfoReducer,
		emptyBarState,
		regionInfoInit
	);

	const getRegions = useCallback(() => {
		fetchRegions();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getRegions();
		barDispatch({ type: "reset", payload: bar || emptyBarState });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bar]);

	const handleInputChange = (event) => {
		barDispatch({
			type: "update",
			payload: {
				name: event.target.name,
				value: event.target.value
			}
		});
	};

	const handleReset = () => {
		barDispatch({
			type: "reset"
		});
	};

	const handleSave = (e) => {
		e.preventDefault();

		// get rid of all the fields we don't care about
		// this makes the merge cleaner
		const {
			// position,
			// barCoverImage,
			// barImages,
			// hasChanged,
			// original,
			...bar
		} = barState;

		if (isNew) bar.createdBy = currentUser.uid;

		if (validateData(bar)) {
			saveBar(id, bar).then(() => {
				openNote(
					`Successfully ${isNew ? "created" : "updated"} bar`
				);
			});
		} else {
			openNote("Bar must have Name, Latitude, and Longitude");
		}
	};

	const handleCancel = (e) => {
		e.preventDefault();
		history.replace("/bars");
	};

	const validateData = (bar) => {
		const notValid = false;
		// validate barName is required
		if (!bar.barName?.trim()) return notValid;

		// validate position data is required
		let [lat, lon] = [undefined, undefined];

		if (typeof bar.latitude !== "number") {
			lat = bar.latitude?.trim();
			lat = parseFloat(lat);
		} else {
			lat = bar.latitude;
		}

		if (typeof bar.longitude !== "number") {
			lon = bar.longitude?.trim();
			lon = parseFloat(lon);
		} else {
			lon = bar.longitude;
		}

		if (!lat || !lon) return notValid;
		if (isNaN(lat) || isNaN(lon)) return notValid;

		return true;
	};

	return (
		<RegionInfoContainer>
			<div className="form-section large">
				<TextField
					className="header-like"
					label="Region Name*"
					name="Name"
					fullWidth
					inputProps={{
						style: {
							fontSize: "1.8rem"
						}
					}}
					value={barState.Name}
					onChange={handleInputChange}
				/>
			</div>
			<div className="form-footer">
				{isNew ? (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleCancel}
					>
						Cancel
					</Button>
				) : barState.hasChanged ? (
					<Button
						color="primary"
						variant={"outlined"}
						onClick={handleReset}
					>
						Clear Changes
					</Button>
				) : null}
				<Button
					color="primary"
					variant={
						barState.hasChanged || isNew
							? "contained"
							: "outlined"
					}
					onClick={handleSave}
				>
					Save
				</Button>
			</div>
		</RegionInfoContainer>
	);
}

const emptyBarState = {
	barName: "",
	barEmail: "",
	barPhone: "",
	barUrl: "",
	barAddress: "",

	barCoverImage: "",
	barImages: [],
	barDescription: "",
	liveUrl: "",

	barOpenDays: "",
	barClosingHours: "",
	barOpeningHours: "",
	hasChanged: false,

	position: {
		geopoint: {
			latitude: 0,
			longitude: 0
		}
	}
};

function regionInfoInit(regionInfo) {
	return {
		original: regionInfo,
		...regionInfo,
		hasChanged: false,
		latitude: regionInfo?.position?.geopoint?.latitude,
		longitude: regionInfo?.position?.geopoint?.longitude
	};
}

function regionInfoReducer(state, action) {
	const { type, payload } = action;
	let hasChanged = false;
	switch (type) {
		case "reset":
			return regionInfoInit(payload || state.original);
		case "update":
			hasChanged = true;
			return {
				...state,
				[payload.name]: payload.value,
				hasChanged
			};
		default:
			return state;
	}
}

const RegionInfoContainer = styled.form`
	max-width: 800px;
	margin: auto;
	background: ${THEME_MAP["color-basic-100"]};
	border-radius: 4px;
	border: 1px solid ${THEME_MAP["color-basic-400"]};
	padding: 16px 24px;

	min-height: 100px;
	margin-bottom: 16px;

	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.form-section {
		display: flex;
		flex-direction: column;
		width: 48%;
		margin-bottom: 32px;

		&.large {
			width: 100%;
		}

		& > div {
			margin: 8px 0;
		}
	}

	.image-uploads {
		background: ${THEME_MAP["color-basic-300"]};
		border-radius: 3px;
		height: 300px;

		display: flex;
		align-items: center;
		justify-content: space-evenly;

		img {
			height: 268px;
			width: auto;
		}
	}

	.form-footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		& > button {
			margin-left: 16px;
		}
	}
`;

// const emptyCategories = {
// 	monday: {},
// 	tuesday: {},
// 	wednesday: {},
// 	thursday: {},
// 	friday: {},
// 	saturday: {},
// 	sunday: {}
// };
// const DAYS = [
// 	"monday",
// 	"tuesday",
// 	"wednesday",
// 	"thursday",
// 	"friday",
// 	"saturday",
// 	"sunday"
// ];

// function CategorySection({ bar, openNote }) {
// 	const categorySnapshots = useCategories();
// 	const fetchCategories = useFetchCategories();
// 	const { saveBarCategories } = useBarUtils();

// 	const [categories, setCategories] = useState([]);
// 	const [activeCategory, setActiveCategory] = useState();
// 	const [selectedSubcategories, setSubcategories] =
// 		useState(emptyCategories);
// 	const [hasChanged, setHasChanged] = useState(false);

// 	const activeName = activeCategory?.categoryName || "default";

// 	useEffect(() => {
// 		setSubcategories(bar?.categories || emptyCategories);
// 		setHasChanged(false);
// 	}, [bar]);

// 	useEffect(() => {
// 		if (categorySnapshots.length < 1) fetchCategories();
// 		setCategories(
// 			categorySnapshots.map((cat, idx) => {
// 				let category = cat.data();
// 				if (!activeCategory && idx === 0)
// 					setActiveCategory(category);
// 				return category;
// 			})
// 		);
// 	}, [categorySnapshots]);

// 	const handleCategorySelect = (day, catName, event) => {
// 		setHasChanged(true);
// 		setSubcategories((current) => ({
// 			...current,
// 			[day]: {
// 				...current[day],
// 				[catName]: event.target.value
// 			}
// 		}));
// 	};

// 	const handleSave = (e) => {
// 		e.preventDefault();
// 		// do the stuff
// 		saveBarCategories(selectedSubcategories).then(() => {
// 			openNote("Categories updated successfully");
// 		});
// 	};

// 	const handleReset = (e) => {
// 		e.preventDefault();
// 		setSubcategories(bar?.categories || emptyCategories);
// 		setHasChanged(false);
// 	};

// 	return (
// 		<CategoryContainer>
// 			<h4>CATEGORY & SERVICES</h4>
// 			<div className="tab-selectors">
// 				{categories.map((category, index) => {
// 					return (
// 						<Button
// 							key={category.categoryName}
// 							variant="contained"
// 							color={
// 								!activeCategory && index === 0
// 									? "primary"
// 									: activeName === category.categoryName
// 										? "primary"
// 										: "default"
// 							}
// 							onClick={() => setActiveCategory(category)}
// 						>
// 							{category.categoryName}
// 						</Button>
// 					);
// 				})}
// 			</div>
// 			<div className="select-container">
// 				{DAYS.map((day) => (
// 					<FormControl key={day} className="form-control">
// 						<InputLabel id="category-select">{day}</InputLabel>
// 						<Select
// 							labelId="category-select"
// 							id="category-multi-select"
// 							multiple
// 							value={
// 								selectedSubcategories[day]?.[activeName] || []
// 							}
// 							onChange={(event) =>
// 								handleCategorySelect(day, activeName, event)
// 							}
// 							input={<Input />}
// 							renderValue={(selected) => (
// 								<div className="chip-container">
// 									{selected.map((value) => (
// 										<Chip key={value} label={value} />
// 									))}
// 								</div>
// 							)}
// 							fullWidth
// 						>
// 							{activeCategory?.subcategories?.map((sub) => (
// 								<MenuItem key={sub} value={sub}>
// 									{sub}
// 								</MenuItem>
// 							))}
// 						</Select>
// 					</FormControl>
// 				))}
// 			</div>
// 			<div className="form-footer">
// 				{hasChanged && (
// 					<Button
// 						color="primary"
// 						variant={"outlined"}
// 						onClick={handleReset}
// 					>
// 						Clear Changes
// 					</Button>
// 				)}
// 				<Button
// 					color="primary"
// 					variant={hasChanged ? "contained" : "outlined"}
// 					onClick={handleSave}
// 				>
// 					Save
// 				</Button>
// 			</div>
// 		</CategoryContainer>
// 	);
// }
// const CategoryContainer = styled.section`
// 	max-width: 800px;
// 	margin: auto;
// 	background: ${THEME_MAP["color-basic-100"]};
// 	border-radius: 4px;
// 	border: 1px solid ${THEME_MAP["color-basic-400"]};
// 	padding: 16px 24px;

// 	min-height: 100px;

// 	h4 {
// 		margin: 8px 0 24px;
// 	}

// 	.select-container > div {
// 		margin: 16px 0;
// 	}

// 	.tab-selectors {
// 		button {
// 			margin-right: 16px;
// 		}
// 	}

// 	.form-control {
// 		display: block;
// 		width: 100%;
// 		min-width: 200px;

// 		label {
// 			text-transform: capitalize;
// 		}
// 	}

// 	.chip-container {
// 		display: flex;
// 		flex-wrap: wrap;

// 		& > div {
// 			margin-right: 4px;
// 		}
// 	}

// 	.form-footer {
// 		width: 100%;
// 		display: flex;
// 		justify-content: flex-end;

// 		& > button {
// 			margin-left: 16px;
// 		}
// 	}
// `;
