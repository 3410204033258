import React from "react";
import styled from "styled-components";
import { CircularProgress } from "@mui/material";

export const LoadingBlanket = () => (
	<LoadingFog className={"loading-fog is-loading"}>
		<CircularProgress color="primary" />
	</LoadingFog>
);

const LoadingFog = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	align-items: center;
	justify-content: center;
	background: rgba(255, 235, 235, 0.6);
	&.is-loading {
		display: flex;
	}
`;
