import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import {
	Button,
	FormControl,
	Select,
	MenuItem,
	Snackbar,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	DialogContentText,
	TextField,
	CircularProgress
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { THEME_MAP } from "../theme";

import { useManagers, useUserUtils } from "../actions/users";
import { useUserRoles } from "../globalstore";
import { useSelectedBar, useBarUtils } from "../actions/bars";

import firebase from "firebase";

export function BarManagers() {
	const { id: barId } = useParams();
	const managers = useManagers();
	const { fetchManagers, addManager } = useUserUtils();
	const selectedBar = useSelectedBar();
	const { fetchBarById } = useBarUtils();

	const [isAddingManager, setIsAddingManager] = useState(false);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [email, setEmail] = useState("");

	const [isNoteOpen, setIsNoteOpen] = useState(false);
	const [note, setNote] = useState("");

	const closeNote = (_event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setNote("");
		setIsNoteOpen(false);
	};

	const openNote = (message) => {
		setNote(message);
		setIsNoteOpen(true);
	};

	useEffect(() => {
		barId && fetchBarById(barId);
	}, [barId, fetchBarById]);

	useEffect(() => {
		selectedBar?.id && fetchManagers(selectedBar);
	}, [selectedBar, fetchManagers]);

	const toggleDialog = () => {
		setEmail("");
		setIsDialogOpen((c) => !c);
	};

	const sendResetEmail = async () => {
		await firebase.auth().sendPasswordResetEmail(email);
	};

	const addNewManager = () => {
		setIsAddingManager(true);
		addManager({
			email,
			barId: selectedBar.id,
			barName: selectedBar.get("barName"),
			role: "manager"
		})
			.then(() => {
				sendResetEmail();
				setIsAddingManager(false);
				setIsDialogOpen(false);
				openNote("Successfully Added user");
			})
			.catch((e) => {
				setIsAddingManager(false);
				openNote(e.message);
			});
	};

	return (
		<Container>
			<div className="filters">
				<Button
					color="primary"
					variant="contained"
					onClick={toggleDialog}
				>
					Add manager
				</Button>
			</div>
			<div className="users-container">
				{managers?.map((snapshot) => (
					<UserListItem
						key={snapshot.id}
						userSnapshot={snapshot}
						openNote={openNote}
					/>
				))}
			</div>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right"
				}}
				open={isNoteOpen}
				autoHideDuration={3000}
				onClose={closeNote}
				message={note}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={closeNote}
					>
						<Close fontSize="small" />
					</IconButton>
				}
			/>
			<Dialog open={isDialogOpen} onClose={toggleDialog}>
				<DialogTitle>Add manager</DialogTitle>
				<DialogContent>
					<DialogContentText>
						To add a manager please enter their email address
						and submit.
					</DialogContentText>
					<TextField
						autoFocus
						margin="dense"
						label="Email Address"
						type="email"
						fullWidth
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						disabled={isAddingManager}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={toggleDialog}
						color="primary"
						disabled={isAddingManager}
					>
						Cancel
					</Button>
					<div
						style={{
							display: "inline-block",
							position: "relative"
						}}
					>
						<Button
							onClick={addNewManager}
							variant="contained"
							color="primary"
							disabled={isAddingManager}
						>
							Add manager
						</Button>
						{isAddingManager && (
							<CircularProgress
								style={{
									position: "absolute",
									width: "24px",
									height: "24px",
									top: "calc(50% - 12px)",
									left: "calc(50% - 12px)"
								}}
							/>
						)}
					</div>
				</DialogActions>
			</Dialog>
		</Container>
	);
}

const Container = styled.div`
	height: 100%;
	width: 100%;

	overflow: auto;

	padding: 24px;

	.input {
		background: #fff;
	}

	.filters {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 16px;
	}

	.users-container {
		width: 100%;
		min-height: 100px;
		border-radius: 4px;
		border: 1px solid ${THEME_MAP["color-basic-400"]};
		background: ${THEME_MAP["color-basic-100"]};

		margin-bottom: 16px;

		max-height: calc(100% - 125px);
		overflow-y: scroll;
	}
`;
const ROLES = ["owner", "manager"];

function UserListItem({ userSnapshot, openNote }) {
	const { userRoles: { roles: currentUserRoles, isAdmin }} = useUserRoles();
	const { id: barId } = useParams();

	const barSnapshot = useSelectedBar();

	const [currentRef] = useState(userSnapshot.ref);
	const [user, setUser] = useState({
		id: userSnapshot?.id,
		...(userSnapshot?.data() || {})
	});

	const [showDeleteAlert, setShowDeleteAlert] = useState(false);

	const { setUserRole, removeBarFromUser } = useUserUtils();

	useEffect(() => {
		if (currentRef) {
			return currentRef.onSnapshot((snap) => {
				setUser({ id: snap.id, ...snap.data() });
			});
		}
	}, [currentRef]);

	const handleRoleChange = (e) => {
		setUserRole(user, barSnapshot, e.target.value)
			.then(() => {
				openNote("Successfully updated user");
			})
			.catch(() => {
				openNote("We were unable to update the user");
			});
	};

	const removeBarPermissions = (e) => {
		removeBarFromUser(user, barId);
	};

	const canEditRoles =
		isAdmin || currentUserRoles?.[barId]?.role === "owner";

	const dialogView = (
		<Dialog
			open={showDeleteAlert}
			onClose={() => setShowDeleteAlert(false)}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">
				{"Are you sure?"}
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Would you like to proceed with deleting this manager?
					<br />
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => setShowDeleteAlert(false)}
					color="primary"
				>
					Cancel
				</Button>
				<Button
					onClick={(e) => {
						e.stopPropagation();
						e.preventDefault();
						removeBarPermissions();
					}}
					color="primary"
					autoFocus
				>
					Delete
				</Button>
			</DialogActions>
		</Dialog>
	);
	return (
		<PermissionItem>
			<span className="col-big username">
				{user?.email || "Email"}
			</span>
			<span className={"col-big"}>
				<FormControl>
					<Select
						value={user.roles?.[barId]?.role || ""}
						onChange={handleRoleChange}
						displayEmpty
						inputProps={{ "aria-label": "Current Role" }}
						color="primary"
						disabled={!canEditRoles}
					>
						{ROLES.map((role) => (
							<MenuItem key={role} value={role}>
								{role}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</span>
			<Button
				onClick={() => setShowDeleteAlert(true)}
				aria-label="remove permissions"
				variant="outlined"
			>
				DELETE
			</Button>
			{dialogView}
		</PermissionItem>
	);
}

const PermissionItem = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 8px 16px;
	margin: 4px 0;

	&:hover {
		background: ${THEME_MAP["color-primary-transparent-100"]};
	}

	.col-big {
		width: calc(100% - 42px);
	}

	.MuiFormControl-root {
		width: 200px;
	}
`;
