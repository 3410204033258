import firebase from "firebase/app";
import * as geofirex from "geofirex";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

// Initialize Firebase
const apiKey = process.env.REACT_APP_FIREBASE_API_KEY;
const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
const databaseURL = process.env.REACT_APP_DATABASE_URL;
const projectId = process.env.REACT_APP_PROJECT_ID;
const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
const appId = process.env.REACT_APP_APP_ID;
const measurementId = process.env.REACT_APP_MEASUREMENT_ID;

const firebaseConfig = {
	apiKey,
	authDomain,
	databaseURL,
	projectId,
	storageBucket,
	messagingSenderId,
	appId,
	measurementId
};

export const fbApp = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

export const firestore = firebase.firestore();
export const geo = geofirex.init(firebase);

export const storage = firebase.storage();
export const imageRef = storage.ref("images/");
export const profileImageRef = storage.ref("profileImages/");
export const localAdsImageRef = storage.ref("localAds/");

export const functions = firebase.functions();
//functions.useFunctionsEmulator("http://localhost:5001"); //uncoomment this line to test firebase functions locally

export const EmailAuthProvider = firebase.auth.EmailAuthProvider;
export const LOCAL_PERSIST = firebase.auth.Auth.Persistence.LOCAL;

export const getDeleteSentinel = firebase.firestore.FieldValue.delete;
