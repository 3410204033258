import React, { useEffect, useState } from "react";

import { BarsProvider } from "./actions/bars";
import { PromotionsProvider } from "./actions/promotions";
import { CategoriesProvider } from "./actions/categories";
import { UsersProvider } from "./actions/users";

import { auth, firestore } from "./services/firebase";
import { LoadingBlanket } from "./components/LoadingBlanket";
import { RegionsProvider } from "./actions/regions";
import { PromotionPlansProvider } from "./actions/promotionPlans";
import { FeaturePlacementsProvider } from "./actions/featurePlacements";
import { LocalAdsProvider } from "./actions/localAds";

/**
 * this is where we can add context providers for global state
 */

const UserRolesContext = React.createContext({
	isAdmin: false,
	roles: {},
	status: "fetching"
});

const noop = () => {};

export function StateProvider(props) {
	const [isAuthReady, setIsAuthReady] = useState(false);
	const [userRoles, setUserRoles] = useState({
		isAdmin: false,
		roles: {},
		status: "fetching"
	});

	const getRoles = () => {
		let unsub = { current: () => {} };

		auth.onAuthStateChanged((user) => {
			setIsAuthReady(true);
			if (user?.uid) {
				unsub.current = firestore
					.collection("Users")
					.where("email", "==", user.email)
					.get()
					.then((snap) => {
						if (snap.docs.length === 0) {
							firestore
								.collection("Users")
								.add({
									email: user.email
								})
								.then((snap) => {
									setUserRoles({
										id: snap.docs[0].id,
										...snap.docs[0].data(),
										status: "idle"
									});
								});
						} else {
							setUserRoles({
								id: snap.docs[0].id,
								...snap.docs[0].data(),
								status: "idle"
							});
						}
					});
			} else {
				setUserRoles((current) => ({
					...current,
					status: "idle"
				}));
			}

			const query = firestore
				.collection("Users")
				.where("email", "==", user.email);

			query.onSnapshot(
				(querySnapshot) => {
					const userRoles = {
						id: querySnapshot.docs[0].id,
						...querySnapshot.docs[0].data(),
						status: "idle"
					};

					setUserRoles(userRoles);
				},
				(error) => {
					console.log(`Error updating roles: ${error}`);
				}
			);
		});

		return () => (unsub.current ? unsub.current() : noop());
	};

	useEffect(() => {
		return getRoles();
	}, []);

	if (!isAuthReady) return <LoadingBlanket />;

	return (
		<UserRolesContext.Provider value={{ userRoles, getRoles }}>
			<LocalAdsProvider>
				<FeaturePlacementsProvider>
					<CategoriesProvider>
						<UsersProvider>
							<RegionsProvider>
								<PromotionPlansProvider>
									<PromotionsProvider>
										<BarsProvider>
											{props.children}
										</BarsProvider>
									</PromotionsProvider>
								</PromotionPlansProvider>
							</RegionsProvider>
						</UsersProvider>
					</CategoriesProvider>
				</FeaturePlacementsProvider>
			</LocalAdsProvider>
		</UserRolesContext.Provider>
	);
}

export const useUserRoles = () => React.useContext(UserRolesContext);
